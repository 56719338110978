import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
    backgroundSize: "contain",
  },
  cardLink: {
    display: "block",
    color: "gray",
    textDecoration: "none",
    "&:hover": {
      color: "primary",
    },
  },
});

function MediaCard({ to, image, title }) {
  const classes = useStyles();

  return (
    <>
      <Link to={to} className={classes.cardLink}>
        <Card className={classes.card}>
          <CardActionArea>
            <CardMedia className={classes.media} image={image} />
            <CardContent>
              <Typography variant="subtitle1">{title}</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </>
  );
}

export default MediaCard;
