import React, { useState } from "react";
//import { Link } from "react-router-dom";
import {
  makeStyles,
  Button,
  CircularProgress,
  Container,
  Snackbar,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
//import Textfield from "../Components/FormsUI/Textfield/TextfieldWrapper";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";

import { PeoplePicker } from "@microsoft/mgt-react";
import { Formik } from "formik";
import * as Yup from "yup";
import Textfield from "../Components/FormsUI/Textfield/TextfieldWrapper";
import Members from "../Components/ManageDL/TabMembers"
import Owners from "../Components/ManageDL/TabOwners"

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  btn: {
    margin: theme.spacing(1),
    marginTop: 10,
    marginBottom: 20,
  },
  progressBar: {
    display: "inline-block",
    position: "relative",
    justifyContent: "center",
  },
  divFloatLeft: {
    float: "left",
    marginTop: "0px"
  }
}));

const INITIAL_FORM_STATE = {
  groupName: "",
};

let REQUEST = {
  groupName: "",
  requestedBy: "",
};

const FORM_VALIDATION = Yup.object().shape({
  // groupName: Yup.string()
  //   .required("Required")
  //   .matches(
  //     /^DL_[a-zA-Z0-9_ ]*$/,
  //     "DL name should be alphanumeric and should start with DL_"
  //   ),
});

function DeleteDL() {  
  const classes = useStyles();
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isNonOrgUserSelected, setIsNonOrgUserSelected] = useState(false);
  const [memberPeopleMessage, setMemberPeopleMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [snackSeverity, setSnackBarSeverity] = useState("info");
  const [snackMessage, setSnackMessage] = useState("");
  // const [disableBtn, setDisableBtn] = useState(true);

  const callUnsubscribeDLAPI = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await UnsubscribeDLAPI(token, request);
    });
  };

  const UnsubscribeDLAPI = async (token, request) => {
    let data = ""
    let errorData = "";
    let persons22 = [];
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var personsArray = request.groupName.split(";");
    console.log(personsArray);
    const promises = await personsArray.map(async function(item) {
    //for (const item of personsArray){
        item = item.replace(/\s+/g, '');
        var unsubscribeDLURL = GROUP_API_URL + "/api/GetUserById?id=" + item;
        const res = await fetch(unsubscribeDLURL, options);
      
        if (res.status !== 200) {
          data = await res.text();
          errorData += item + ";";
          console.log("severity -- ", res.status.toString())
          return false
        } else {
          data = await res.json();
          //selectedPerson.push(data);
          console.log("apiGetUserByID", JSON.stringify(data));
          //console.log(selectedPerson);
          persons22.push(data);
          return true
        }
    })
    Promise.all(promises).then(() => {
      setSelectedPerson(persons22);
      console.log("mapPersonsArray");
      console.log(persons22);
      //setSelectedPerson(mapPersonsArrayss)
      console.log(errorData)
      if (errorData !== ""){
        setIsNonOrgUserSelected(true);
        setMemberPeopleMessage("Following ids could not be resolved: " + errorData)
      }
      setLoading(false);
    })
    
    // await Promise.all(promises).then((value) => {
    //   mapPersonsArrayss.push(value)
    // })
    
  };

  const openSnackBar = (snackOpen, snackSev, snackMes) => {
    setOpen(snackOpen);
    setSnackMessage(snackMes);
    setSnackBarSeverity(snackSev);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setSnackMessage("");
    //setDisableBtn(false);
  };

  return (
    <>
    <Container>
      <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          // autoHideDuration={6000}
      >
          <Alert severity={snackSeverity} onClose={handleClose}>
          {snackMessage}
          </Alert>
      </Snackbar>
      <Members dlName="DL_SPTeam" isDynamicDL={false} classes={classes}/>
      <Owners dlName="DL_SPTeam" classes={classes} openSnackBarMain={openSnackBar}/>
      <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values, onSubmitProps) => {  
            setLoading(true);
            REQUEST.groupName = values.groupName;
            console.log("group name ", values.groupName);
            console.log(JSON.stringify(REQUEST));
            callUnsubscribeDLAPI(REQUEST);            
            console.log("*** FINISH ON SUBMIT ***");
          }}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            getFieldProps,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              <Textfield
                required
                name="groupName"
                label="DL Name"
                className={classes.field}
              />
              {/* {selectedPerson.length > 0 && ( */}
              <PeoplePicker
                    placeholder="Start typing manager name"
                    selectedPeople={selectedPerson}
                    userType="user"
                  />
                  {isNonOrgUserSelected && <Alert severity="error">
                          {memberPeopleMessage}
                        </Alert>}
              {/* )} */}

              <Button
                className={classes.btn}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<Delete />}
              >
                Remove from DL
              </Button>

              {loading && <CircularProgress />}
      
          </form>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default DeleteDL;
