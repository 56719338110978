import React, { useState } from "react";
import DataTable from "./DataTable";
import { PeoplePicker } from "@microsoft/mgt-react";
import { Delete, Add } from "@material-ui/icons";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";
import {
  Switch,
  Typography,
  FormControlLabel,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const userColumns = [
  { field: "userPrincipalName", headerName: "User Id", width: 250 },
  { field: "displayName", headerName: "Name", width: 350 },
  { field: "mail", headerName: "Email", width: 350 },
];

const AuthSenders = ( props ) => {
  const classes = props.classes;
  const [showMemebers, setShowMembers] = useState(false);
  const [groupAuthSenders, setGroupAuthSenders] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false);  
  
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);  

  const openSnackBar = (snacOpen, snackSev, snackMess) =>{
    props.openSnackBarMain(snacOpen, snackSev, snackMess)
  }
 
  const isNonOrgUserSelectedInPeople = (data) => {
    let isExternalUser = data.map(user => {
      if(!(user.userPrincipalName && user.userPrincipalName.includes("@corpaa.aa.com"))){
        return true;
      }
      return false;
    }).filter(i => i === true);
    //console.log("** isExternalUser - " + isExternalUser)
    if(isExternalUser.length > 0) return true;
    else return false;
  }

  const handleUserSelectionChanged = (e) => {
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      //setDisableBtn(false);      
      console.log("length:", e.target.selectedPeople.length);
      if (e.target.selectedPeople.length > 0) setDisableBtn(false);
      if (e.target.selectedPeople.length === 0) setDisableBtn(true);
      setUserList(e.target.selectedPeople);
    }
    else{
      setDisableBtn(true);
      setNonOrgUserSelected(true);
    }
  };

  const callUpdateAuthSenders = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await updateAuthSenders(token, request);
    });
  };

  const updateAuthSenders = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiUpdateAuthSenders = GROUP_API_URL + "/api/UpdateAuthorizedSenders";

    const res = await fetch(apiUpdateAuthSenders, options);

    if (res.status !== 200) {
      data = await res.text();
      //setSnackBarSeverity("error");
      openSnackBar(true, "error", data);
      setIsLoading(false);
      setDisableBtn(false);
    } else {
      data = await res.text();
      //setSnackBarSeverity("success");
      setIsLoading(false);
      setDisableBtn(false);
      openSnackBar(true, "success", data);
      console.log("updateAuthSenders API", JSON.stringify(data));
    }
    //setUpdateMemberResult(data);
  };

  const handleAddRemoveAuthSenders = (e, option) => {
    setDisableBtn(true);
    setIsLoading(true);
    console.log("onclick", props.dlName);
    const request = {
      groupName: props.dlName,
      updateAuthorizedSenders: [],
    };
    console.log("selected users:", userList);
    console.log(userList.map((users) => users.userPrincipalName));
    request.updateAuthorizedSenders = userList.map((users) => {
      return {
        requestType: e.currentTarget.value,
        userId: users.userPrincipalName,
      };
    });
    console.log(JSON.stringify(request));
    //setUsers(userList.map((users) => users.userPrincipalName));
    //console.log(users);
    callUpdateAuthSenders(request);
    //setOpen(true);
  };

  const getGroupAuthSenders = async (token, selectedDLName) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var dlNameWithFix = selectedDLName.replace("&", "%26");
    var apiGroupAuthSenders =
      GROUP_API_URL + "/api/GetAuthorizedSenders?groupName=" + dlNameWithFix;

    const res = await fetch(apiGroupAuthSenders, options);

    if (res.status !== 200) {
      data = await res.text();
      console.log("Error in getGroupAuthSenders", res.status);
    } else {
      data = await res.json();
      setGroupAuthSenders(data);
      // console.log("getGroupAuthSenders", JSON.stringify(data));
    }
  };

  const handleShowAuthSendersChange = async (event) => {
    const showChecked = event.target.checked;
    if(event.target.checked){
      await Providers.globalProvider.getAccessToken().then(async function (res) {
        const token = res;
        getGroupAuthSenders(token, props.dlName).then(function(){
          setIsLoading(false);
          setShowMembers(showChecked);
        });
      });
    }
    else{
        setShowMembers(showChecked);
    }
  };

  return (
    <>
    <Card><CardContent>
    <Typography variant="h6" gutterBottom>
        Manage Authorized Senders of a Distribution List
    </Typography>
    <Typography variant="body2" gutterBottom>
        The action of adding authorized sender(s) to a distribution
        list will restrict the usage of the DL to only those members
        listed as Authorized Senders. No one else will be able to send
        an email to the DL.
    </Typography>
    <Typography variant="body2" gutterBottom>
        If you would like to unrestrict a DL so that anyone can send
        an email to that DL, then you must remove all Authoried
        senders.
    </Typography>
    <Typography variant="h6" display="block" gutterBottom>
        Add/Remove Authorized Senders
    </Typography>
    <PeoplePicker
        placeholder="Start typing user name... You can select multiple users"
        selectionChanged={handleUserSelectionChanged}
        userType="user"
    />
    {isNonOrgUserSelected && <Alert severity="error">
                Selected User could not be resolved.
            </Alert>}
    <Button
        disabled={disableBtn || props.disableBtn}
        variant="contained"
        color="primary"
        className={classes.btn}
        endIcon={<Add />}
        value="Add"
        onClick={(e) => {
        handleAddRemoveAuthSenders(e, "autorizedSender");
        }}
    >
        Add user(s)
    </Button>
    <Button
        disabled={disableBtn || props.disableBtn}
        variant="contained"
        color="secondary"
        className={classes.btn}
        endIcon={<Delete />}
        value="Remove"
        onClick={(e) => {
        handleAddRemoveAuthSenders(e, "autorizedSender");
        }}
    >
        Remove user(s)
    </Button>

    <div>
        <FormControlLabel
        control={
            <Switch
            checked={showMemebers}
            onChange={handleShowAuthSendersChange}
            name="checked"
            color="primary"
            />
        }
        label="Show Authorized Senders"
        />
        {isLoading && (
            <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle2">
                Loading Data Please wait ...
                </Typography>
                <CircularProgress />
            </Grid>
        )}
        {showMemebers && (
        <DataTable
            rows={groupAuthSenders}
            columns={userColumns}
            disableSelectionOnClick={true}
            showRemoveButton={false}
        />
        )}
    </div>
        </CardContent></Card>
    </>
  );
};

export default AuthSenders;
