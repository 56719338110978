import React, { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Providers } from "@microsoft/mgt-element";
import {
  Typography,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  TextField,
} from "@material-ui/core";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const DeleteDL = ( props ) => {
  const classes = props.classes;
  const [justification, setJustification] = useState("");
  
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);  

  const openSnackBar = (snacOpen, snackSev, snackMess) =>{
    props.openSnackBarMain(snacOpen, snackSev, snackMess)
  }

  const callDeleteGroup = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await deleteGroup(token, request);
    });
  };

  const deleteGroup = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiDeleteGroup = GROUP_API_URL + "/api/DeleteGroup";

    const res = await fetch(apiDeleteGroup, options);

    if (res.status !== 200) {
      data = await res.text();
      //setSnackBarSeverity("error");
      openSnackBar(true, "error", data);
      setIsLoading(false);
      setDisableBtn(false);
    } else {
      data = await res.text();
      //setSnackBarSeverity("success");
      setIsLoading(false);
      setDisableBtn(false);
      openSnackBar(true, "success", data);
      // console.log("apiDeleteGroup API", JSON.stringify(data));
    }
    //setUpdateMemberResult(data);
  };

  const handleDeleteGroup = (e) => {
    setDisableBtn(true);
    setIsLoading(true);
    const request = {
      groupName: props.dlName,
      groupType: "DistributionList",
      justification: justification,
    };

    console.log(JSON.stringify(request));
    callDeleteGroup(request);    
  };
 
  const handleOnBJChange = (e) => {
    setDisableBtn(false);
    setJustification(e.target.value);
  };

  return (
    <>
    <Card><CardContent>
        <Typography variant="body2" gutterBottom>
          Once you delete a DL it cannot be restored / recovered.
        </Typography>
        <TextField
            value={justification}
            fullWidth
            className={classes.field}
            label="Justification"
            variant="outlined"
            onChange={handleOnBJChange}
        />
        <Button
            disabled={disableBtn || props.disableBtn}
            variant="contained"
            color="secondary"
            className={classes.btn}
            endIcon={<Delete />}
            onClick={handleDeleteGroup}
        >
            Remove DL
        </Button>
        {isLoading && (
            <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle2">
                Loading Data Please wait ...
                </Typography>
                <CircularProgress />
            </Grid>
        )}
    </CardContent></Card>
    </>
  );
};

export default DeleteDL;
