import React from "react";
import {
  CardContent,
  Typography,
  Card,
} from "@material-ui/core";

const About = ( props ) => {  
  return (
    <>
    <Card><CardContent>
      <Typography variant="h6" display="block" gutterBottom>
          {props.dl && props.dl.displayName}
      </Typography>
      <Typography variant="subtitle2" display="block">
          Description
      </Typography>
      <Typography variant="body1" gutterBottom>
          {props.dl && props.dl.description}
      </Typography>
      <Typography variant="subtitle2">Email</Typography>
      <Typography variant="body1" gutterBottom>
          {props.dl && props.dl.mail}
      </Typography>
    </CardContent></Card>
    </>
  );
};

export default About;
