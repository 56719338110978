import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Container,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    links: {
      textDecoration: "none",
    },
    field: {
      marginTop: 20,
      marginBottom: 20,
      display: "block",
    },
  };
});

function EmailServices() {
  const classes = useStyles();
  return (
    <div>
      <Container>
        {/* <Breadcrumbs aria-label="breadcrumb" className={classes.field}>
          <Link to="/home" style={{ color: "gray", textDecoration: "none" }}>
            Home
          </Link>
          <Typography color="textPrimary"> Email Services</Typography>
        </Breadcrumbs> */}
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="New Email Account"
                subheader="Request for new email account"
              />
              <CardActions>
                <Button color="primary">Employee</Button>
                <Button color="primary">Contractor</Button>
                <Button color="primary">Shared</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="Existing Distribution List"
                subheader="Manage or Delete an existing distribution list"
              />
              <CardActions>
                <Link to="/manage-dl" className={classes.links}>
                  <Button color="primary">Manage</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="New Distribution List"
                subheader="Request new email distribution list"
              />
              <CardActions>
                <Link to="/new-dl" className={classes.links}>
                  <Button color="primary">Submit</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="Mailbox Permissions"
                subheader="Request for mailbox permissions"
              />
              <CardActions>
                <Button color="primary">Submit</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="Email Address Changes"
                subheader="Request a Change in an Email Address"
              />
              <CardActions>
                <Button color="primary">Submit</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card>
              <CardHeader
                title="Remove from Distribution List"
                subheader="Remove yourself from a distribution list"
              />
              <CardActions>
                <Link to="/unsubscribe-dl" className={classes.links}>
                  <Button color="primary">Submit</Button>
                </Link>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default EmailServices;
