import React from "react";
import NewDLForm from "../Pages/NewDLForm";

function NewDL() {
  return (
    <div>
      <NewDLForm />
    </div>
  );
}

export default NewDL;
