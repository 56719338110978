import React, { useState } from "react";
import DataTable from "./DataTable";
import { PeoplePicker } from "@microsoft/mgt-react";
import { Delete, Add } from "@material-ui/icons";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";
import {
  Switch,
  Typography,
  FormControlLabel,
  Button,
  CircularProgress,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const userColumns = [
  { field: "userPrincipalName", headerName: "User Id", width: 250 },
  { field: "displayName", headerName: "Name", width: 350 },
  { field: "mail", headerName: "Email", width: 350 },
];

const Owners = ( props ) => {
  const classes = props.classes;
  const [showMemebers, setShowMembers] = useState(false);
  const [groupOwners, setGroupOwners] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);  

  const openSnackBar = (snacOpen, snackSev, snackMess) =>{
    props.openSnackBarMain(snacOpen, snackSev, snackMess)
  }
 
  const isNonOrgUserSelectedInPeople = (data) => {
    let isExternalUser = data.map(user => {
      if(!(user.userPrincipalName && user.userPrincipalName.includes("@corpaa.aa.com"))){
        return true;
      }
      return false;
    }).filter(i => i === true);
    //console.log("** isExternalUser - " + isExternalUser)
    if(isExternalUser.length > 0) return true;
    else return false;
  }

  const handleUserSelectionChanged = (e) => {
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      //setDisableBtn(false);      
      console.log("length:", e.target.selectedPeople.length);
      if (e.target.selectedPeople.length > 0) setDisableBtn(false);
      if (e.target.selectedPeople.length === 0) setDisableBtn(true);
      setUserList(e.target.selectedPeople);
    }
    else{
      setDisableBtn(true);
      setNonOrgUserSelected(true);
    }
  };

  const updateOwners = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiUpdateOwners = GROUP_API_URL + "/api/UpdateGroupOwners";

    const res = await fetch(apiUpdateOwners, options);

    if (res.status !== 200) {
      data = await res.text();
    //   setSnackBarSeverity("error");
    //   setSnackMessage(data);
    //   setOpen(true);
      openSnackBar(true, "error", data);
      setIsLoading(false);
      setDisableBtn(false);
    } else {
      data = await res.text();
    //   setSnackBarSeverity("success");
    //   setSnackMessage(data);
    //   setOpen(true);
      setIsLoading(false);
      setDisableBtn(false);
      openSnackBar(true, "success", data);
      console.log("updateOwners API", JSON.stringify(data));
    }
  };

  const callUpdateOwners = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await updateOwners(token, request);
    });
  };

  const handleAddRemoveOwners = (e, option) => {
    setDisableBtn(true);
    setIsLoading(true);
    console.log("onclick", props.dlName);
    const request = {
      groupName: props.dlName,
      updateOwners: [],
    };
    console.log("selected users:", userList);
    console.log(userList.map((users) => users.userPrincipalName));
    request.updateOwners = userList.map((users) => {
      return {
        requestType: e.currentTarget.value,
        ownerId: users.userPrincipalName,
      };
    });
    console.log(JSON.stringify(request));
    //setUsers(userList.map((users) => users.userPrincipalName));
    //console.log(users);
    callUpdateOwners(request);
  };

  const getGroupOwners = async (token, selectedDLName) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var dlNameWithFix = selectedDLName.replace("&", "%26");
    var apiGroupOwners =
      GROUP_API_URL + "/api/GetGroupOwners?groupName=" + dlNameWithFix;

    const res = await fetch(apiGroupOwners, options);

    if (res.status !== 200) {
      data = await res.text();
      console.log("Error in getGroupOwners", res.status);
    } else {
      data = await res.json();
      setGroupOwners(data);
      // console.log("apiGroupOwners", JSON.stringify(data));
    }
  };

  const handleShowOwnersChange = async (event) => {
    const showChecked = event.target.checked;
    if(showChecked){
      setIsLoading(true);  
      setDisableBtn(true);   
      await Providers.globalProvider.getAccessToken().then(async function (res) {
        const token = res;
        getGroupOwners(token, props.dlName).then(function(){
          setIsLoading(false);
          setDisableBtn(true);
          setShowMembers(showChecked);
        });
      });
    }
    else{
        setShowMembers(showChecked);
    }
  };

  return (
    <>
    <Card><CardContent>
      <Typography variant="h6">Add/Remove Owner</Typography>
        <PeoplePicker
            placeholder="Start typing user name... You can select multiple users"
            selectionChanged={handleUserSelectionChanged}
            userType="user"
        />
        {isNonOrgUserSelected && <Alert severity="error">
                    Selected User could not be resolved.
                </Alert>}
        <Button
            disabled={disableBtn || props.disableBtn}
            variant="contained"
            color="primary"
            className={classes.btn}
            endIcon={<Add />}
            value="Add"
            onClick={(e) => {
            handleAddRemoveOwners(e, "owner");
            }}
        >
            Add Owner(s)
        </Button>
        <Button
            disabled={disableBtn || props.disableBtn}
            variant="contained"
            color="secondary"
            className={classes.btn}
            endIcon={<Delete />}
            value="Remove"
            onClick={(e) => {
            handleAddRemoveOwners(e, "owner");
            }}
        >
            Remove Owner(s)
        </Button>
        <div>
            <FormControlLabel
            control={
                <Switch
                checked={showMemebers}
                onChange={handleShowOwnersChange}
                name="checked"
                color="primary"
                />
            }
            label="Show Owners"
            />
            {isLoading && (
                <Grid container direction="row" alignItems="center">
                    <Typography variant="subtitle2">
                    Loading Data Please wait ...
                    </Typography>
                    <CircularProgress />
                </Grid>
                )}
            {showMemebers && (
            <DataTable
                rows={groupOwners}
                columns={userColumns}
                disableSelectionOnClick={true}
                showRemoveButton={false}
            />
            )}
        </div>
        </CardContent></Card>
    </>
  );
};

export default Owners;
