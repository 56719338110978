import React, { useState } from "react";
import DataTable from "./DataTable";
import { PeoplePicker } from "@microsoft/mgt-react";
import { Delete, Add } from "@material-ui/icons";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";
import {
  Switch,
  Typography,
  FormControlLabel,
  Card,
  CardContent,
  RadioGroup,
  FormControl,
  Radio,
  Button,
  TextField,
  CircularProgress,
  Grid,
} from "@material-ui/core";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const userColumns = [
  { field: "userPrincipalName", headerName: "User Id", width: 250 },
  { field: "displayName", headerName: "Name", width: 350 },
  { field: "mail", headerName: "Email", width: 350 },
];

const Members = ( props ) => {
  const classes = props.classes;
  const [showMemebers, setShowMembers] = useState(false);
  const [radioValue, setRadioValue] = useState("users");
  const [groupMembers, setGroupMembers] = useState([]);
  const [userList, setUserList] = useState([]);
  //const [users, setUsers] = useState([]);
  const [contactValue, setContactValue] = useState("");
  const [dlValue, setDlValue] = useState("");
  const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  // const [open, setOpen] = useState(false);
  // const [snackSeverity, setSnackBarSeverity] = useState("");
  // const [snackMessage, setSnackMessage] = useState("");
  const [disableBtn, setDisableBtn] = useState(true);
  // const [bulkUsers, setbulkUsers] = useState("");
  // const [showBulkUpdate, setShowBulkUpdate] = useState(false);

  const openSnackBar = (snacOpen, snackSev, snackMess) =>{
    props.openSnackBarMain(snacOpen, snackSev, snackMess)
  }

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  //   setSnackBarSeverity("");
  //   setSnackMessage("");
  //   setDisableBtn(false);
  // };

  const getGroupMembers = async (token) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var dlNameWithFix = props.dlName.replace("&", "%26");
    var apiGroupMembers =
      GROUP_API_URL +
      "/api/GetMembersByGroupName?name=" +
      dlNameWithFix +
      "&getAllMembers=true";

    const res = await fetch(apiGroupMembers, options);

    if (res.status !== 200) {
      data = await res.text();
      console.log("Error in getGroupMembers", res.status, data);      
      // setSnackMessage(data);
      // setSnackBarSeverity("error");        
      // setOpen(true);
      openSnackBar(true, "error", "Error occured while getting group members.");
    } else {
      data = await res.json();
      setGroupMembers(data);
      console.log("apiGroupMembers", JSON.stringify(data));
    }
  };

  const callUpdateMembers = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await updateMembers(token, request);
    });
  };

  const updateMembers = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiUpdateMembers = GROUP_API_URL + "/api/UpdateGroupMembers";

    const res = await fetch(apiUpdateMembers, options);

    if (res.status !== 200) {
      data = await res.text();
      // setSnackBarSeverity("success");
      // setSnackMessage(data);
      // setOpen(true);
      openSnackBar(true, "error", "Error occured while updating group members.");
      setIsLoading(false);
      setDisableBtn(false);
    } else {
      data = await res.text();
      // setSnackBarSeverity("success");
      // setSnackMessage(data);
      // setOpen(true);
      openSnackBar(true, "success", data);
      setIsLoading(false);
      setDisableBtn(false);
      console.log("updateMembers API", JSON.stringify(data));
    }
  };

  const handleShowMembersChange = async (event) => {  
    const showChecked = event.target.checked;
    console.log("ShowMembersChange", event.target.checked)  
    if(showChecked){
      setDisableBtn(true);
      setIsLoading(true);      
      await Providers.globalProvider.getAccessToken().then(async function (res) {
        const token = res;
        console.log(token);
        console.log("dlname", props.dlName);
        getGroupMembers(token).then(function() {          
          setIsLoading(false);
          setDisableBtn(false);
          setShowMembers(showChecked);
        });
      });
    }
    else{
        setShowMembers(showChecked);
    }
  };
  const handleRadioSelection = (event) => {
    setDisableBtn(true);
    setRadioValue(event.target.value);
  };

  // const isNonOrgUserSelectedInPeople_old = (data) => {
  //   let isExternalUser = data.map(user => {
  //     if(!(user.personType && user.personType.subclass && user.personType.subclass === "OrganizationUser")){
  //       return true;
  //     }
  //     return false;
  //   }).filter(i => i === true);
  //   //console.log("** isExternalUser - " + isExternalUser)
  //   if(isExternalUser.length > 0) return true;
  //   else return false;
  // }
  const isNonOrgUserSelectedInPeople = (data) => {
    console.log("selected user details:")
    console.log(data)
    let isExternalUser = data.map(user => {
      if(!(user.userPrincipalName && user.userPrincipalName.toLowerCase().includes("@corpaa.aa.com"))){
        return true;
      }
      return false;
    }).filter(i => i === true);
    //console.log("** isExternalUser - " + isExternalUser)
    if(isExternalUser.length > 0) return true;
    else return false;
  }

  const handleUserSelectionChanged = (e) => {
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      //setDisableBtn(false);      
      console.log("length:", e.target.selectedPeople.length);
      if (e.target.selectedPeople.length > 0) setDisableBtn(false);
      if (e.target.selectedPeople.length === 0) setDisableBtn(true);
      setUserList(e.target.selectedPeople);
    }
    else{
      setDisableBtn(true);
      setNonOrgUserSelected(true);
    }
  };

  const handleAddRemoveUser = (e, option) => {
    setDisableBtn(true);
    setIsLoading(true);
    // console.log("onclick", props.dlName);
    let memberType;
    if (option === "user") memberType = "User";
    if (option === "contact") memberType = "Contact";
    if (option === "dl") memberType = "DistributionList";
    console.log("memberType", memberType);
    const request = {
      groupName: props.dlName,
      updateMembers: [],
    };
    console.log("selected users:", userList);
    console.log(userList.map((users) => users.userPrincipalName));
    request.updateMembers = userList.map((users) => {
      return {
        requestType: e.currentTarget.value,
        memberType: memberType,
        memberId: users.userPrincipalName,
      };
    });
    console.log(JSON.stringify(request));
    //setUsers(userList.map((u) => u.userPrincipalName));
    //console.log(users);
    callUpdateMembers(request);
    //setOpen(true);
  };

  // const handleAddRemoveData = (e, option) => {
  //   console.log("contact value:", contactValue);
  //   setDisableBtn(true);
  //   let memberType;
  //   let memberId;
  //   if (option === "contact") {
  //     memberType = "Contact";
  //     memberId = contactValue;
  //   }
  //   if (option === "dl") {
  //     memberType = "DistributionList";
  //     memberId = dlValue;
  //   }
  //   console.log("memberType", memberType);
  //   const request = {
  //     groupName: props.dlName,
  //     updateMembers: [
  //       {
  //         requestType: e.currentTarget.value,
  //         memberType: memberType,
  //         memberId: memberId,
  //       },
  //     ],
  //   };
  //   console.log("request is:", request);
  //   callUpdateMembers(request);
  //   setDlValue("");
  //   setContactValue("");
  //   //setOpen(true);
  // };
  const handleAddRemoveContact = async (option) => {
    console.log("handleAddRemoveContact Contact value:", contactValue);
    console.log("option: ", option);
    setDisableBtn(true);
    setIsLoading(true);

    // check if DL exists
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };
      var apiGetContact = GROUP_API_URL + "/api/GetContactByMail?mail=" + contactValue;

      const resGetContact = await fetch(apiGetContact, options);

      if (resGetContact.status !== 200) {
        openSnackBar(true, "error", "Contact could not be found.");
        setIsLoading(false);
        setDisableBtn(false);
        return false;
      } else {
        const request = {
          groupName: props.dlName,
          updateMembers: [
            {
              requestType: option,
              memberType: "Contact",
              memberId: contactValue,
            },
          ],
        };
        console.log("request is:", request);
        callUpdateMembers(request);
        setContactValue("");
      }
    });
  };

  const handleAddRemoveDL = async (option) => {
    console.log("handleAddRemoveDL dl value:", dlValue);
    console.log("option: ", option);
    setDisableBtn(true);
    setIsLoading(true);

    // check if DL exists
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      var headers = new Headers();
      var bearer = "Bearer " + token;
      headers.append("Authorization", bearer);
      var options = {
        method: "GET",
        headers: headers,
      };
      var dlNameWithFix = dlValue.replace("&", "%26");
      var apiGroupByName = GROUP_API_URL + "/api/GetGroupByName?name=" + dlNameWithFix;

      const resGetGroupByName = await fetch(apiGroupByName, options);

      if (resGetGroupByName.status !== 200) {
        openSnackBar(true, "error", "DL could not be found.");
        setIsLoading(false);
        setDisableBtn(false);
        return false;
      } else {
        const request = {
          groupName: props.dlName,
          updateMembers: [
            {
              requestType: option,
              memberType: "DistributionList",
              memberId: dlValue,
            },
          ],
        };
        console.log("request is:", request);
        callUpdateMembers(request);
        setDlValue("");
      }
    });
  };

  const handleContactOnChange = (e) => {
    setDisableBtn(false);
    setContactValue(e.target.value);
  };
  const handleDlOnChange = (e) => {
    setDisableBtn(false);
    setDlValue(e.target.value);
  };

  // const CheckNames = async (token, request) => {
  //   let data = ""
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(request),
  //   };
    
  //   var apiGetUsersBulk = GROUP_API_URL + "/api/GetUsers";
  //   const res = await fetch(apiGetUsersBulk, options);
  //   if (res.status !== 200) {
  //     data = await res.text();
  //     openSnackBar(true, "error", data);
  //     setIsLoading(false);
  //     setDisableBtn(false);
  //   } else {
  //     data = await res.text();
  //     openSnackBar(true, "success", data);
  //     setIsLoading(false);
  //     setDisableBtn(false);
  //     console.log("updateMembers API", JSON.stringify(data));
  //   }
  // };
  
  // const callCheckNames = async (request) => {
  //   await Providers.globalProvider.getAccessToken().then(async function (res) {
  //     const token = res;
  //     await CheckNames(token, request);
  //   });
  // }
  
  // const handleCheckNames = async () => {
  //   setDisableBtn(true);
  //   setIsLoading(true);
  //   const request = {
  //     userIds: bulkUsers.split(";"),
  //   };
  //   callCheckNames(request);
  // };  

  // const handleShowBulkUpdateChange = async () => {  
  //   console.log("ShowBulkUserChange")  
  //   setShowBulkUpdate(true);
  // };

  // const handleBulkUserChange = async (e) => {
  //   console.log(e.target.value)
  //   setbulkUsers(e.target.value)
  //   if(e.target.value)
  //     setDisableBtn(false);
  // }

  return (
    <>
      <div>
      {/* <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          // autoHideDuration={6000}
        >
          <Alert severity={snackSeverity} onClose={handleClose}>
            {snackMessage}
          </Alert>
        </Snackbar> */}
        <Card>
            <CardContent>
            {props.isDynamicDL && 
                <Alert severity="info">
                Adding / Removing members to a dynamic group is not allowed. Select Modify tab to update filter conditions for this DL.  
                </Alert>
            }
            {/* {showBulkUpdate && (
              <>
              <TextField
                    value={bulkUsers}
                    fullWidth
                    className={classes.field}
                    label="Enter a string of comma-separated user IDs or email addresses and click Check Names"
                    variant="outlined"
                    onChange={handleBulkUserChange}
                />
              <Button
                  disabled={disableBtn || props.disableBtn}
                  value="CheckNames"
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  endIcon={<Check />}
                  onClick={(e) => {
                    handleCheckNames();
                  }}
              >
                  Check Names
              </Button>
              </>
            )}             */}
            {!props.isDynamicDL &&  (
            <>
                <Typography variant="h6">Add/Remove Members</Typography>
            <FormControl component="fieldset">
                {/* <FormLabel component="legend">Select</FormLabel> */}
                <RadioGroup
                row
                name="selectOptions"
                value={radioValue}
                onChange={handleRadioSelection}
                >
                <FormControlLabel
                    labelPlacement="start"
                    value="users"
                    control={<Radio color="primary" />}
                    label="Users"
                />
                <FormControlLabel
                    labelPlacement="start"
                    value="contacts"
                    control={<Radio color="primary" />}
                    label="Contacts"
                />
                <FormControlLabel
                    labelPlacement="start"
                    value="dl"
                    control={<Radio color="primary" />}
                    label="DLs"
                />
                </RadioGroup>
            </FormControl>

            {radioValue === "users" && (
                <>   
                <PeoplePicker
                  placeholder="Start typing user name... You can select multiple users"
                  selectionChanged={handleUserSelectionChanged}
                  userType="user"
                />
                {/* <Link
                  component="button"
                  variant="body2"
                  className={classes.divFloatRight}
                  onClick={() => {
                    handleShowBulkUpdateChange()
                  }}
                >
                  Use free text
                </Link> */}
                {isNonOrgUserSelected && <Alert severity="error">
                    Selected User could not be resolved.
                </Alert>} 
                <Button
                    disabled={disableBtn || props.disableBtn || userList.length === 0}
                    value="Add"
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    endIcon={<Add />}
                    onClick={(e) => {
                    handleAddRemoveUser(e, "user");
                    }}
                >
                    Add User
                </Button>
                <Button
                    disabled={disableBtn || props.disableBtn || userList.length === 0}
                    value="Remove"
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    endIcon={<Delete />}
                    onClick={(e) => {
                    handleAddRemoveUser(e, "user");
                    }}
                >
                    Remove User
                </Button>
                </>
            )}
            {radioValue === "contacts" && (
                <>
                <Typography variant="body2" gutterBottom>
                  You can only add a contact which has already been created and avilable in Outlook GAL. 
                </Typography>
                <TextField
                    value={contactValue}
                    fullWidth
                    className={classes.field}
                    label="Contact Email"
                    variant="outlined"
                    onChange={handleContactOnChange}
                    type="email"
                />
                <Button
                    disabled={disableBtn || props.disableBtn}
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    endIcon={<Add />}
                    value="Add"
                    onClick={(e) => {
                      handleAddRemoveContact("Add");
                    }}
                >
                    Add Contacts
                </Button>
                <Button
                    disabled={disableBtn || props.disableBtn}
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    endIcon={<Delete />}
                    value="Remove"
                    onClick={(e) => {
                      handleAddRemoveContact("Remove");
                    }}
                >
                    Remove Contacts
                </Button>
                </>
            )}
            {radioValue === "dl" && (
                <>
                <Typography variant="body2" gutterBottom>
                  Enter DL name as it appears in Outlook (eg. DL_DistributionList Name). Do not enter email address.
                </Typography>
                <TextField
                    value={dlValue}
                    fullWidth
                    className={classes.field}
                    label="DL"
                    variant="outlined"
                    onChange={handleDlOnChange}
                />
                <Button
                    disabled={disableBtn || props.disableBtn}
                    variant="contained"
                    color="primary"
                    className={classes.btn}
                    endIcon={<Add />}
                    value="Add"
                    onClick={(e) => {
                      handleAddRemoveDL("Add");
                    }}
                >
                    Add DL
                </Button>
                <Button
                    disabled={disableBtn || props.disableBtn}
                    variant="contained"
                    color="secondary"
                    className={classes.btn}
                    endIcon={<Delete />}
                    value="Remove"
                    onClick={(e) => {
                      handleAddRemoveDL("Remove");
                    }}
                >
                    Remove DL
                </Button>
                </>
            )}
            </>)}
            <div>
                <FormControlLabel
                control={
                    <Switch
                    checked={showMemebers}
                    onChange={handleShowMembersChange}
                    name="checked"
                    color="primary"
                    />
                }
                label="Show Members"
                />
                {/* <FormControlLabel
                control={
                    <Switch
                    checked={showBulkUpdate}
                    onChange={handleShowBulkUpdateChange}
                    name="checked"
                    color="primary"
                    />
                }
                label="Use a string of comma-separated user IDs"
                /> */}
                {isLoading && (
                <Grid container direction="row" alignItems="center">
                    <Typography variant="subtitle2">
                    Loading Data Please wait ...
                    </Typography>
                    <CircularProgress />
                </Grid>
                )}
                {showMemebers && (
                <DataTable
                    rows={groupMembers}
                    columns={userColumns}
                    checkboxSelection={!props.isDynamicDL}
                    showRemoveButton={!props.isDynamicDL}
                    dlName={props.dlName}
                />
                )}
            </div>
            </CardContent>
        </Card>
      </div>
    </>
  );
};

export default Members;
