import React, { useState } from "react";
//import { Link } from "react-router-dom";
import {
  makeStyles,
  Typography,
  Button,
  CircularProgress,
  Snackbar,
  Container,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Textfield from "../Components/FormsUI/Textfield/TextfieldWrapper";
import { Formik } from "formik";
import { Alert } from "@material-ui/lab";
import * as Yup from "yup";
import { Providers } from "@microsoft/mgt-element";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const useStyles = makeStyles((theme) => {
  return {
    field: {
      marginTop: 20,
      marginBottom: 20,
      display: "block",
    },
    card: {
      marginTop: 20,
      maxWidth: "100%",
    },
  };
});

const INITIAL_FORM_STATE = {
  groupName: "",
};

let REQUEST = {
  groupName: "",
  requestedBy: "",
};

const FORM_VALIDATION = Yup.object().shape({
  groupName: Yup.string()
    .required("Required")
    .matches(
      /^DL_[a-zA-Z0-9_ ]*$/,
      "DL name should be alphanumeric and should start with DL_"
    ),
});

function UnsubscribeDL() {  
  const classes = useStyles();
  const [snackBarSeverity, setsnackBarSeverity] = useState(""); 
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [unsubscribeDLResponse, setUnsubscribeDLResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const callUnsubscribeDLAPI = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await UnsubscribeDLAPI(token, request);
    });
  };

  const UnsubscribeDLAPI = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var unsubscribeDLURL = GROUP_API_URL + "/api/UnsubscribeFromGroup";

    const res = await fetch(unsubscribeDLURL, options);
    data = await res.text();
    if (res.status !== 200) {
      console.log("severity -- ", res.status.toString())
      setsnackBarSeverity("error");
    } else {
      setsnackBarSeverity("success");
    }
    console.log(data);
    setSnackBarOpen(true);
    setUnsubscribeDLResponse(data);
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <>
      <Container>
      <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values, onSubmitProps) => {  
            setEnableSubmit(false);   
            setLoading(true);
            REQUEST.groupName = values.groupName;
            console.log("group name ", values.groupName);
            console.log(JSON.stringify(REQUEST));
            callUnsubscribeDLAPI(REQUEST);            
            console.log("*** FINISH ON SUBMIT ***");
          }}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            getFieldProps,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              {/* <Breadcrumbs aria-label="breadcrumb" className={classes.field}>
                <Link
                  to="/home"
                  style={{ color: "gray", textDecoration: "none" }}
                >
                  Home
                </Link>
                <Link
                  to="/email-services"
                  style={{ color: "gray", textDecoration: "none" }}
                >
                  Email Services
                </Link>
                <Typography color="textPrimary">New Email DL</Typography>
              </Breadcrumbs> */}
              <Typography variant="h5" className={classes.field}></Typography>
              Remove yourself from an existing Distribution List, please enter the DL name and click Remove from DL. 
              <br />
              <ul>
                <li>You can only remove yourself from a static DL used for team members communication.</li>
                <li>You cannot remove yourself from a filter based DL (dynamically populated). Filters are based on cost center, location, department and direct report.</li>
                <li>This request will not work with any other types of mail distribution groups like DG, O365 groups.</li>
              </ul>
              <Textfield
                required
                name="groupName"
                label="DL Name"
                className={classes.field}
              />

              <Button
                className={classes.btn}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<Delete />}
                disabled={!dirty || !isValid || !enableSubmit || isSubmitting}
              >
                Remove from DL
              </Button>
              {loading && <CircularProgress />}

              <Snackbar
                anchorOrigin={{ vertical: "center", horizontal: "center" }}
                open={snackBarOpen}
                autoHideDuration={null}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={snackBarSeverity}>
                  {unsubscribeDLResponse}
                </Alert>
              </Snackbar>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default UnsubscribeDL;
