import React, { useState, useEffect } from "react";
import { Providers } from "@microsoft/mgt-element";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import {
    Grid,
    CircularProgress,
    Typography,
    makeStyles,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";
import { Delete, Done } from "@material-ui/icons";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;
const ENV = process.env.REACT_APP_ENV;
 
const useStyles = makeStyles({
    gridStyle: {
        width: '1215px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '24px',
        fontSize: '.75rem'
    },
    customMuiDataGrid: {
        fontSize: '.75rem'
    },
  });

const SharedMailboxes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [currentUserInfo, setCurrentUserInfo] = useState({});
    const [currentDate, setCurrentDate] = useState("");
    const [spoSites, setSPOSites] = useState([]);
    const [open, setOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState("");

    const classes = useStyles();
    function CustomToolbar() {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
                csvOptions={{
                    fileName: 'SharedMailboxes',
                    allColumns: true
                }}
            />
          </GridToolbarContainer>
        );
      }

    const columns = [
        { field: 'accountId', headerName: 'Account Id', width: 175 },
        { field: 'mail', headerName: 'Email', width: 225 },
        { field: 'owner', headerName: 'Owner', width: 225 },
        { field: 'managedBy', headerName: 'Managed By', width: 225 },
        { field: 'action', headerName: 'Retain / Delete', width: 175},
        { field: 'useraction', 
            headerName: ' ', 
            width: 175,
            sortable: false,
            disableClickEventBubbling: true,
            align: 'center',
            renderCell: (params) => {
                const onClickAction = async function(actionTaken) {
                    console.log(JSON.stringify(params.row, null, 4))
                    console.log("action ", actionTaken)
                    // call api
                    setDialogContent("Please wait...");
                    setOpen(true);
                    var headers = new Headers();
                    let request = {
                        Id: params.row.id,
                        mail: params.row.mail,
                        Action: actionTaken,
                        ActionDate: currentDate,
                        ActionBy: currentUserInfo.userPrincipalName
                    };
                    await Providers.globalProvider.getAccessToken().then(async (res) => {
                        const token = res;
                        if (token) {
                            var bearer = "Bearer " + token;
                            headers.append("Authorization", bearer);
                            var options = {
                                method: "POST",
                                headers: headers,
                                body: JSON.stringify(request),
                            };

                            var apiGetMyGroups = GROUP_API_URL + "/api/UpdateMySharedMailbox";
                            
                            fetch(apiGetMyGroups, options)
                            .then((res) => {
                                if(res.status !== 200){
                                    throw new Error ("Something went wrong, please refresh the page and try again.");
                                }else {
                                    return res.json()
                                }
                            })
                            .then((data) => {
                                console.log(data);
                                const objIndex = spoSites.findIndex((obj => obj.id === params.id));
                                const newSPOSites = [...spoSites]
                                newSPOSites[objIndex].action = actionTaken; 
                                setSPOSites(newSPOSites);
                                if(actionTaken === 'Delete'){
                                    setDialogContent("Your request to delete Shared Mailbox '" + params.row.mail + "' has been submitted.");
                                }
                                else{
                                    setDialogContent("Your request to apply retention policy on the Shared Mailbox '" + params.row.mail + "' has been submitted.");
                                }
                            })
                            .catch(() => {
                                setDialogContent("Something went wrong, please refresh the page and try again");
                                setIsLoading(false);
                            })
                        }
                    });
                    // end of call api
                };
                return (
                    <>
                    <Tooltip title="Retain">
                        <IconButton
                            //disabled={disableButtons}
                            onClick={() => onClickAction('Retain')}
                        >
                            <Done/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            //disabled={disableButtons}
                            onClick={() => onClickAction('Delete')}
                        >
                            <Delete/>
                        </IconButton> 
                    </Tooltip>
                    </>
                );
            }
        },        
    ];

    useEffect(() => {
        (async () => {
        try {
            console.log("page loading....")
            setIsLoading(true);
            // set current Date 
            var d = new Date();
            var dformat = [d.getMonth()+1,
               d.getDate(),
               d.getFullYear()].join('/');
            console.log(dformat);
            setCurrentDate(dformat);

            // call api to get user details and user's spo sites
            var headers = new Headers();
            await Providers.globalProvider.getAccessToken().then(async function (res) {
            const token = res;
            if (token) {
                var bearer = "Bearer " + token;
                headers.append("Authorization", bearer);
                var options = {
                    method: "GET",
                    headers: headers,
                };

                var apiCurrentUser = GROUP_API_URL + "/api/GetCurrentUserInfo";
                if(ENV === "local")
                    apiCurrentUser += "?userId=972802@corpaa.aa.com";
                fetch(apiCurrentUser, options)
                .then((resp) => {
                    if(resp.status !== 200){
                        throw new Error("Something went wrong, please refresh the page and try again.");
                    }else {
                        return resp.json()
                    } 
                })  
                .then((data) => {
                    console.log(JSON.stringify(data));
                    setCurrentUserInfo(data);

                    var apiGetMyGroups = GROUP_API_URL + "/api/GetMySharedMailboxes?userId=" + data.userPrincipalName;
                    fetch(apiGetMyGroups, options)
                    .then((resp) => {
                        if(resp.status !== 200){
                            throw new Error("Something went wrong, please refresh the page and try again.");
                        }
                        else {
                            return resp.json()
                        }
                    })
                    .then((data) => {
                        console.log(JSON.stringify(data));
                        setSPOSites(data);
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setOpen(true);
                        setDialogContent("Something went wrong, please refresh the page and try again.");
                        setIsLoading(false);
                    })
                })
                .catch(() => {
                    setOpen(true);
                    setDialogContent("Something went wrong, please refresh the page and try again.");
                    setIsLoading(false);
                })
            }
            });
        } catch (e) {
            throw new Error(e.message || "Something went wrong, please refresh the page and try again.");
        }
        })();
    }, []);

      const handleCloseDialog = () => {
        setOpen(false);
      };

    return (
        <>
        <div className={classes.gridStyle}> 
        <Typography variant="subtitle1" display="block" >Shared Mailbox Cleanup</Typography>
        <br />
        <Typography variant="body2" gutterBottom>
            Following appropriate record retention practices is essential to the proper functioning of American&apos;s business, as well as meet our legal requirements. 
            By cleaning up your shared mailboxes, you&apos;re helping American enhance our data storage practices while also reducing data privacy and cybersecurity risks associated with keeping unnecessary mail. 
            Please take the time to review the Records Retention Policies found <a target="_blank" rel="noreferrer" href="https://newjetnet.aa.com/docs/DOC-29421">here</a>. 
            For any questions regarding Records Retention Policies please contact <a href="mailto:records.manager@aa.com" target="_top">records.manager@aa.com</a>.
            <br /><br />
            Below you will find the list of all Shared Mailbox you&apos;re an owner or manager. We appreciate you taking the time to review and provide an action.
            <br />
            <ul>
                <li>
                    If the Shared Mailbox is still needed, click the check icon to apply one-year retention policy on the mailbox.
                </li>
                <li>
                    If the Shared Mailbox is no longer needed, click the delete icon to delete the mailbox.
                </li>
            </ul>            
            If you have questions or need more information about your Shared Mailbox contact <a href="mailto:Email.Services@aa.com" target="_top">Email.Services@aa.com</a>.
            <br /><br />
        </Typography>
        {isLoading && (
          <Grid container direction="row" alignItems="center">
            <Typography variant="subtitle2">
              Loading Data Please wait ...
            </Typography>
            <CircularProgress />
          </Grid>
        )}
               
        <DataGrid 
            rows={spoSites} 
            columns={columns} 
            pageSize={100} 
            // checkboxSelection
            // onRowSelected={rowSelection_onChange}
            className={classes.customMuiDataGrid}
            autoHeight
            disableSelectionOnClick
            components={{
                Toolbar: CustomToolbar,
              }}
            getRowHeight={() => 'auto'}
        />
        </div>
        {/* <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackBarOpen}
            autoHideDuration={null}
            >
            <Alert onClose={handleClose} severity={snackBarSeverity}>
                {snackBarMessage}
            </Alert>
        </Snackbar>    */}
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
            {"SharePoint Sites"}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {dialogContent}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={handleCloseDialog}>Ok</Button>
            </DialogActions>
        </Dialog>   
        </>
    );
}

export default SharedMailboxes;
