import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "../Components/Home";
import EmailServices from "../Components/EmailServices";
import NewDL from "../Components/NewDL";
// import ManageDL from "../Components/ManageDL";
import ManageDL from "../Components/ManageDL/ManageDL";
// import NewDLStepper from "../Components/NewDL/NewDLStepper";
import UnsubscribeDL from "../Pages/UnsubscribeDL";
import DeleteDL from "../Pages/DeleteDL";
import SPOSites from "../Pages/SPOSites";
import SharedMailboxes from "../Pages/SharedMailbox"

function RoutingPage() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/email-services" exact component={EmailServices} />
        <Route path="/new-dl" exact component={NewDL} />
        {/* <Route path="/new-dls" exact component={NewDLStepper} /> */}
        <Route path="/manage-dl" exact component={ManageDL} />
        <Route path="/delete-dl" exact component={DeleteDL} />
        <Route path="/unsubscribe-dl" exact component={UnsubscribeDL} />
        <Route path="/sposites" exact component={SPOSites} />
        <Route path="/sharedmailbox" exact component={SharedMailboxes} />
      </Switch>
    </Router>
  );
}

export default RoutingPage;
