// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App/App";
//import reportWebVitals from "./reportWebVitals";

// import { msalConfig } from "./App/Config/authConfig";

// import { Providers } from "@microsoft/mgt-element";
// import { MsalProvider } from "@microsoft/mgt-msal-provider";

// Providers.globalProvider = new MsalProvider(msalConfig);

if (process.env.REACT_APP_ENV === "prod")
    console.log = () => {};

//const isIE = /*@cc_on!@*/false || !!document.documentMode;

// const App = React.lazy(() => import('./App/App'));
// const AppSelector = () => { 
//   const mystyle = {
//     border: "1px solid",
// 	  margin: "10px auto",
// 	  padding: "15px 10px 15px 50px",
// 	  width: "380px",
// 	  color: "#D8000C"
//   };
//   return (
//     <>
//       <React.Suspense fallback={<></>}>
//         {isIE && (<div style={mystyle}> IE is not supported. Please use Chrome or Edge. </div>)}
//         {!isIE && <App />}
//       </React.Suspense>
//     </>
//   )
// }

ReactDOM.render(
  <React.StrictMode>
        <App />
  </React.StrictMode>,
  // <AppSelector></AppSelector>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
