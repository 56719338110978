import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import EmailFilters from "../EmailFilters";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";
import {
  makeStyles,
  useTheme,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Grid,
  Snackbar,
} from "@material-ui/core";
import About from './TabAbout'
import Members from './TabMembers'
import Owners from "./TabOwners";
import AuthSenders from "./TabAuthSenders";
import DeleteDL from "./TabDelete";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;
//const ENV = process.env.REACT_APP_ENV;

// const userColumns = [
//   { field: "userPrincipalName", headerName: "User Id", width: 250 },
//   { field: "displayName", headerName: "Name", width: 350 },
//   { field: "mail", headerName: "Email", width: 350 },
// ];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography >{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
  },
  field: {
    marginTop: 20,
    marginBottom: 20,
    display: "block",
  },
  btn: {
    margin: theme.spacing(1),
    marginTop: 10,
    marginBottom: 20,
  },
  progressBar: {
    display: "inline-block",
    position: "relative",
    justifyContent: "center",
  },
  divFloatLeft: {
    float: "left",
    marginTop: "0px"
  },
  divFloatRight: {
    float:"right",
  }
}));

const ManageDLForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        var headers = new Headers();
        await Providers.globalProvider.getAccessToken().then(function (res) {
          const token = res;
          if (token) {
            // console.log("Token: " + token);
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
              method: "GET",
              headers: headers,
            };

            var apiGetMyGroups = GROUP_API_URL + "/api/GetMyGroups";
            // if(ENV === "local")
            //   apiGetMyGroups += "?userId=972802@corpaa.aa.com";

            fetch(apiGetMyGroups, options)
              .then((resp) => resp.json())
              .then((data) => {
                // console.log(JSON.stringify(data));
                setAllDLs(data.distributionLists);
                setIsLoading(false);
              });
            
            var apiIsUserAdmin = GROUP_API_URL + '/api/IsUserGroupAdmin';
            fetch(apiIsUserAdmin, options)
              .then((resp) => resp.json())
              .then((data) => {
                console.log("is admin")
                console.log(data)

                if(data.isAdmin === true)
                  setIsAdmin(true);
              })
          }
        });
      } catch (e) {
        throw new Error(e.message || "Something went wrong");
      }
    })();
  }, []);

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  // const [showMemebers, setShowMembers] = useState(false);
  // const [radioValue, setRadioValue] = useState("users");
  const [allDLs, setAllDLs] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [dlName, setDlName] = useState([]);
  const [dlNameAdmin, setDlNameAdmin] = useState([]);
  const [selectedDLData, setSelectedDLData] = useState([]);
  const [showTab, setShowTab] = useState(false);
  // const [groupMembers, setGroupMembers] = useState([]);
  // const [groupOwners, setGroupOwners] = useState([]);
  // const [groupAuthSenders, setGroupAuthSenders] = useState([]);
  // const [userList, setUserList] = useState([]);
  // const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  // const [updateMemberResult, setUpdateMemberResult] = useState("");
  const [snackBarSeverity, setSnackBarSeverity] = useState("");
  const [snackMessage, setSnackMessage] = useState("");
  // const [justification, setJustification] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  // const [contactValue, setContactValue] = useState("");
  // const [dlValue, setDlValue] = useState("");
  // const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false);
  const [isDynamicDL, setIsDynamicDL] = useState(false);

  const openSnackBar = (snackOpen, snackSev, snackMes) => {
    setOpen(snackOpen);
    setSnackMessage(snackMes);
    setSnackBarSeverity(snackSev);
    setDisableBtn(true);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setDisableBtn(false);
    //setUpdateMemberResult("");
    setSnackMessage("");
    setSnackBarSeverity("");
  };

  const handleDlSelectionChange = async (selectedDLName) => {
    let isValidGroup = false;
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      //await getGroupMembers(token, selectedDLName);
      isValidGroup = await getGroupByName(token, selectedDLName);
      console.log("handleDlSelectionChange isValidDL: ", isValidGroup);
      //await getGroupOwners(token, selectedDLName);
      //await getGroupAuthSenders(token, selectedDLName);
    });    
    return isValidGroup;
  };

  // const getGroupMembers = async (token, selectedDLName) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "GET",
  //     headers: headers,
  //   };
  //   var apiGroupMembers =
  //     GROUP_API_URL +
  //     "/api/GetMembersByGroupName?name=" +
  //     selectedDLName +
  //     "&getAllMembers=true";

  //   const res = await fetch(apiGroupMembers, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     console.log("Error in getGroupMembers", res.status, data);
  //   } else {
  //     data = await res.json();
  //     setGroupMembers(data);
  //     console.log("apiGroupMembers", JSON.stringify(data));
  //   }
  // };

  // const getGroupOwners = async (token, selectedDLName) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "GET",
  //     headers: headers,
  //   };
  //   var apiGroupOwners =
  //     GROUP_API_URL + "/api/GetGroupOwners?groupName=" + selectedDLName;

  //   const res = await fetch(apiGroupOwners, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     console.log("Error in getGroupOwners", res.status);
  //   } else {
  //     data = await res.json();
  //     setGroupOwners(data);
  //     // console.log("apiGroupOwners", JSON.stringify(data));
  //   }
  // };

  // const getGroupAuthSenders = async (token, selectedDLName) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "GET",
  //     headers: headers,
  //   };
  //   var apiGroupAuthSenders =
  //     GROUP_API_URL + "/api/GetAuthorizedSenders?groupName=" + selectedDLName;

  //   const res = await fetch(apiGroupAuthSenders, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     console.log("Error in getGroupAuthSenders", res.status);
  //   } else {
  //     data = await res.json();
  //     setGroupAuthSenders(data);
  //     // console.log("getGroupAuthSenders", JSON.stringify(data));
  //   }
  // };

  const getGroupByName = async (token, selectedDLName) => {
    console.log("get group by name");
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var dlNameWithFix = selectedDLName.replace("&", "%26");
    var apiGroupByName =
      GROUP_API_URL + "/api/GetGroupByName?name=" + dlNameWithFix;

    const res = await fetch(apiGroupByName, options);

    if (res.status !== 200) {
      setOpen(true);
      setSnackMessage("Group could not be found.");
      setSnackBarSeverity("error");
      setDisableBtn(true);
      console.log("Error in apiGroupByName");
      return false;
    } else {
      data = await res.json();
      setSelectedDLData(data);
      console.log("selected DL Data: ");
      console.log(data);
      if(data && data.filter && (data.filter.managerFilterEnabled || data.filter.attributeFilterEnabled)){
        setIsDynamicDL(true);
        console.log("isDynamicDl set to true ");
      }
      else 
        setIsDynamicDL(false);
      console.log("filter ", data.filter.managerFilterEnabled, data.filter.attributeFilterEnabled);
      console.log("setIsDynamicDL ", isDynamicDL);
      return true;
    }
  };

  // const callUpdateMembers = async (request) => {
  //   await Providers.globalProvider.getAccessToken().then(async function (res) {
  //     const token = res;
  //     await updateMembers(token, request);
  //   });
  // };

  // const updateMembers = async (token, request) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(request),
  //   };
  //   var apiUpdateMembers = GROUP_API_URL + "/api/UpdateGroupMembers";

  //   const res = await fetch(apiUpdateMembers, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     setSnackBarSeverity("error");
  //   } else {
  //     data = await res.text();
  //     setSnackBarSeverity("success");
  //     console.log("updateMembers API", JSON.stringify(data));
  //   }
  //   setUpdateMemberResult(data);
  // };

  // const callUpdateOwners = async (request) => {
  //   await Providers.globalProvider.getAccessToken().then(async function (res) {
  //     const token = res;
  //     await updateOwners(token, request);
  //   });
  // };

  // const updateOwners = async (token, request) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(request),
  //   };
  //   var apiUpdateOwners = GROUP_API_URL + "/api/UpdateGroupOwners";

  //   const res = await fetch(apiUpdateOwners, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     setSnackBarSeverity("error");
  //   } else {
  //     data = await res.text();
  //     setSnackBarSeverity("success");
  //     console.log("updateOwners API", JSON.stringify(data));
  //   }
  //   setUpdateMemberResult(data);
  // };

  // const callUpdateAuthSenders = async (request) => {
  //   await Providers.globalProvider.getAccessToken().then(async function (res) {
  //     const token = res;
  //     await updateAuthSenders(token, request);
  //   });
  // };

  // const updateAuthSenders = async (token, request) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(request),
  //   };
  //   var apiUpdateAuthSenders = GROUP_API_URL + "/api/UpdateAuthorizedSenders";

  //   const res = await fetch(apiUpdateAuthSenders, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     setSnackBarSeverity("error");
  //   } else {
  //     data = await res.text();
  //     setSnackBarSeverity("success");
  //     console.log("updateAuthSenders API", JSON.stringify(data));
  //   }
  //   setUpdateMemberResult(data);
  // };

  // const callDeleteGroup = async (request) => {
  //   await Providers.globalProvider.getAccessToken().then(async function (res) {
  //     const token = res;
  //     await deleteGroup(token, request);
  //   });
  // };

  // const deleteGroup = async (token, request) => {
  //   let data = "";
  //   var headers = new Headers();
  //   var bearer = "Bearer " + token;
  //   headers.append("Authorization", bearer);
  //   var options = {
  //     method: "POST",
  //     headers: headers,
  //     body: JSON.stringify(request),
  //   };
  //   var apiDeleteGroup = GROUP_API_URL + "/api/DeleteGroup";

  //   const res = await fetch(apiDeleteGroup, options);

  //   if (res.status !== 200) {
  //     data = await res.text();
  //     setSnackBarSeverity("error");
  //   } else {
  //     data = await res.text();
  //     setSnackBarSeverity("success");
  //     // console.log("apiDeleteGroup API", JSON.stringify(data));
  //   }
  //   setUpdateMemberResult(data);
  // };

  const handleSelectDLChange = async (event) => {
    setIsLoading(true);
    setShowTab(false);
    const selectedDL = event.target.value;
    setDlName(selectedDL);
    if (event.target.value !== "") {
      const isValidDL = await handleDlSelectionChange(selectedDL);
      setIsLoading(false);
      if(isValidDL){
        setShowTab(true);
      }
      setValue(0);
      //setShowMembers(false);

      console.log("END HANDLE CHANGE ***");
    }
  };
  
  const handleChangeDLNameAdmin = event => {
    setDlNameAdmin(event.target.value);
  };
  
  const getDLAdminOnClick = async (event) => {
    setIsLoading(true);
    setShowTab(false);
    //const selectedDL = event.target.value;
    console.log(dlNameAdmin);
    setDlName(dlNameAdmin);
    if (event.target.value !== "") {
      const isValidDL = await handleDlSelectionChange(dlNameAdmin);
      console.log(isValidDL);
      setIsLoading(false);
      if(isValidDL){
        setShowTab(true);
      }
      setValue(0);
      //setShowMembers(false);

      console.log("END Admin Get DL ***");
    }
  };

  // const handleShowMembersChange = async (event) => {  
  //   const showChecked = event.target.checked;
  //   console.log("ShowMembersChange", event.target.checked)  
  //   if(event.target.checked){
  //     setIsLoading(true);      
  //     await Providers.globalProvider.getAccessToken().then(async function (res) {
  //       const token = res;
  //       console.log(token);
  //       console.log("dlname", dlName);
  //       getGroupMembers(token, dlName).then(function() {          
  //         setIsLoading(false);
  //         setShowMembers(showChecked);
  //       });
  //     });
  //   }
  // };
  // const handleShowOwnersChange = async (event) => {
  //   const showChecked = event.target.checked;
  //   if(event.target.checked){
  //     setIsLoading(true);     
  //     await Providers.globalProvider.getAccessToken().then(async function (res) {
  //       const token = res;
  //       getGroupOwners(token, dlName).then(function(){
  //         setIsLoading(false);
  //         setShowMembers(showChecked);
  //       });
  //     });
  //   }
  // };
  // const handleShowAuthSendersChange = async (event) => {
  //   const showChecked = event.target.checked;
  //   if(event.target.checked){
  //     await Providers.globalProvider.getAccessToken().then(async function (res) {
  //       const token = res;
  //       getGroupAuthSenders(token, dlName).then(function(){
  //         setIsLoading(false);
  //         setShowMembers(showChecked);
  //       });
  //     });
  //   }
  // };
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    //setShowMembers(false);
    //setDisableBtn(true);
    //setNonOrgUserSelected(false);
    handleClose();
  };

  const handleTabChangeIndex = (index) => {
    setValue(index);
    
  };
  // const handleRadioSelection = (event) => {
  //   setDisableBtn(true);
  //   setRadioValue(event.target.value);
  // };

  // const isNonOrgUserSelectedInPeople_old = (data) => {
  //   let isExternalUser = data.map(user => {
  //     if(!(user.personType && user.personType.subclass && user.personType.subclass === "OrganizationUser")){
  //       return true;
  //     }
  //     return false;
  //   }).filter(i => i === true);
  //   //console.log("** isExternalUser - " + isExternalUser)
  //   if(isExternalUser.length > 0) return true;
  //   else return false;
  // }
  // const isNonOrgUserSelectedInPeople = (data) => {
  //   let isExternalUser = data.map(user => {
  //     if(!(user.userPrincipalName && user.userPrincipalName.includes("@corpaa.aa.com"))){
  //       return true;
  //     }
  //     return false;
  //   }).filter(i => i === true);
  //   //console.log("** isExternalUser - " + isExternalUser)
  //   if(isExternalUser.length > 0) return true;
  //   else return false;
  // }

  // const handleUserSelectionChanged = (e) => {
  //   if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
  //     setNonOrgUserSelected(false);
  //     //setDisableBtn(false);      
  //     console.log("length:", e.target.selectedPeople.length);
  //     if (e.target.selectedPeople.length > 0) setDisableBtn(false);
  //     if (e.target.selectedPeople.length === 0) setDisableBtn(true);
  //     setUserList(e.target.selectedPeople);
  //   }
  //   else{
  //     setDisableBtn(true);
  //     setNonOrgUserSelected(true);
  //   }
  // };

  // const handleAddRemoveUser = (e, option) => {
  //   setDisableBtn(true);
  //   // console.log("onclick", dlName);
  //   let memberType;
  //   if (option === "user") memberType = "User";
  //   if (option === "contact") memberType = "Contact";
  //   if (option === "dl") memberType = "DistributionList";
  //   console.log("memberType", memberType);
  //   const request = {
  //     groupName: dlName,
  //     updateMembers: [],
  //   };
  //   console.log("selected users:", userList);
  //   console.log(userList.map((users) => users.userPrincipalName));
  //   request.updateMembers = userList.map((users) => {
  //     return {
  //       requestType: e.currentTarget.value,
  //       memberType: memberType,
  //       memberId: users.userPrincipalName,
  //     };
  //   });
  //   console.log(JSON.stringify(request));
  //   setUsers(userList.map((users) => users.userPrincipalName));
  //   console.log(users);
  //   callUpdateMembers(request);
  //   setOpen(true);
  // };

  // const handleAddRemoveData = (e, option) => {
  //   console.log("contact value:", contactValue);
  //   setDisableBtn(false);
  //   let memberType;
  //   let memberId;
  //   if (option === "contact") {
  //     memberType = "Contact";
  //     memberId = contactValue;
  //   }
  //   if (option === "dl") {
  //     memberType = "DistributionList";
  //     memberId = dlValue;
  //   }
  //   console.log("memberType", memberType);
  //   const request = {
  //     groupName: dlName,
  //     updateMembers: [
  //       {
  //         requestType: e.currentTarget.value,
  //         memberType: memberType,
  //         memberId: memberId,
  //       },
  //     ],
  //   };
  //   console.log("request is:", request);
  //   callUpdateMembers(request);
  //   setDlValue("");
  //   setContactValue("");
  //   setOpen(true);
  // };

  // const handleAddRemoveOwners = (e, option) => {
  //   setDisableBtn(true);
  //   console.log("onclick", dlName);
  //   const request = {
  //     groupName: dlName,
  //     updateOwners: [],
  //   };
  //   console.log("selected users:", userList);
  //   console.log(userList.map((users) => users.userPrincipalName));
  //   request.updateOwners = userList.map((users) => {
  //     return {
  //       requestType: e.currentTarget.value,
  //       ownerId: users.userPrincipalName,
  //     };
  //   });
  //   console.log(JSON.stringify(request));
  //   setUsers(userList.map((users) => users.userPrincipalName));
  //   console.log(users);
  //   callUpdateOwners(request);
  //   setOpen(true);
  // };

  // const handleAddRemoveAuthSenders = (e, option) => {
  //   setDisableBtn(true);
  //   console.log("onclick", dlName);
  //   const request = {
  //     groupName: dlName,
  //     updateAuthorizedSenders: [],
  //   };
  //   console.log("selected users:", userList);
  //   console.log(userList.map((users) => users.userPrincipalName));
  //   request.updateAuthorizedSenders = userList.map((users) => {
  //     return {
  //       requestType: e.currentTarget.value,
  //       userId: users.userPrincipalName,
  //     };
  //   });
  //   console.log(JSON.stringify(request));
  //   setUsers(userList.map((users) => users.userPrincipalName));
  //   console.log(users);
  //   callUpdateAuthSenders(request);
  //   setOpen(true);
  // };

  // const handleDeleteGroup = (e) => {
  //   setDisableBtn(true);
  //   const request = {
  //     groupName: dlName,
  //     groupType: "DistributionList",
  //     justification: justification,
  //   };

  //   console.log(JSON.stringify(request));
  //   callDeleteGroup(request);
  //   setOpen(true);
  // };

  // const handleOnBJChange = (e) => {
  //   setDisableBtn(false);
  //   setJustification(e.target.value);
  // };

  // const handleContactOnChange = (e) => {
  //   setDisableBtn(false);
  //   setContactValue(e.target.value);
  // };
  // const handleDlOnChange = (e) => {
  //   setDisableBtn(false);
  //   setDlValue(e.target.value);
  // };

  return (
    <>
      <div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          // autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackBarSeverity}>
            {snackMessage}
          </Alert>
        </Snackbar>
        {isAdmin && (
          <div >
          <TextField
                  name="preferredName"
                  label="DL Name"
                  value={dlNameAdmin}
                  helperText="Please type DL Name and click Get DL button"
                  className={[classes.field, classes.divFloatLeft]}
                  onChange={handleChangeDLNameAdmin}
                />
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={getDLAdminOnClick}
            disabled={disableBtn}
          >
            Get DL
          </Button>
          </div>
        )}
        {!isAdmin && 
          <TextField
            disabled={disableBtn}
            className={classes.field}
            select
            label="DL"
            value={dlName}
            onChange={handleSelectDLChange}
            helperText="Please select your DL"
            variant="outlined"
            fullWidth
          >
            {allDLs.map((option, index) => (
              <MenuItem key={index} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        }

        {isLoading && (
          <Grid container direction="row" alignItems="center">
            <Typography variant="subtitle2">
              Loading Data Please wait ...
            </Typography>
            <CircularProgress />
          </Grid>
        )}
        {showTab && (
          <>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="About" {...a11yProps(0)} />
                <Tab label="Members" {...a11yProps(1)} />
                <Tab label="Owners" {...a11yProps(2)} />
                <Tab label="Authorized Senders" {...a11yProps(3)} />
                <Tab label="Modify" {...a11yProps(4)} />
                <Tab label="Delete" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleTabChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <About dl={selectedDLData} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Members dlName={dlName} classes={classes} isDynamicDL={isDynamicDL} disableBtn={disableBtn} openSnackBarMain={openSnackBar} />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                <Owners dlName={dlName} classes={classes} disableBtn={disableBtn} openSnackBarMain={openSnackBar} />
              </TabPanel>
              <TabPanel value={value} index={3} dir={theme.direction}>
                <AuthSenders dlName={dlName} classes={classes} disableBtn={disableBtn} openSnackBarMain={openSnackBar} />
              </TabPanel>
              <TabPanel value={value} index={4} dir={theme.direction}>
                <EmailFilters dlName={dlName} />
              </TabPanel>
              <TabPanel value={value} index={5} dir={theme.direction}>
                <DeleteDL dlName={dlName} classes={classes} disableBtn={disableBtn} openSnackBarMain={openSnackBar} />
              </TabPanel>
            </SwipeableViews>
          </>
        )}
      </div>
    </>
  );
};

export default ManageDLForm;
