import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import image from "../../images/background-img.svg";
// import { Login } from "@microsoft/mgt-react";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: 20,
    textAlign: "center",
    backgroundImage: `url(${image})`,
    height: "50%",
  },

  title: {
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 24,
    lineHeight: 1.3,
  },
  wrapper: {
    height: "100vh",
  },
}));

function WelcomeHeader() {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.wrapper}>
        <Paper className={classes.header}>
          <Typography variant="h2" className={classes.title}>
            Welcome to Group Management
          </Typography>
          <Typography className={classes.subtitle}>Please sign in</Typography>
          {/* <Login /> */}
        </Paper>
      </div>
    </Container>
  );
}

export default WelcomeHeader;
