import React from "react";
import {
  AppBar,
  Container,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import aaLogo from "../../images/aa-logo.png";
import { Login } from "@microsoft/mgt-react";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 150,
    maxHeight: 50,
    marginRight: "10px",
    background: "white",
    flexGrow: 1,
  },
  appbar: {
    backgroundColor: "transparent",
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <div>
      <Container>
        <AppBar position="static" className={classes.appbar}>
          <Toolbar>
            <img src={aaLogo} alt="logo" className={classes.logo} />
            <Typography variant="h6" className={classes.title}></Typography>
            {/* <img src={aaLogo} alt="logo" className={classes.logo} /> */}
            <Login />
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  );
}

export default Header;
