import {
  Button,
  Container,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
  Snackbar,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { KeyboardArrowRight, Add, Remove } from "@material-ui/icons";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import Textfield from "../Components/FormsUI/Textfield/TextfieldWrapper";
import { PeoplePicker } from "@microsoft/mgt-react";
//import { Link } from "react-router-dom";
import { Providers } from "@microsoft/mgt-element";

console.log("env", process.env.REACT_APP_ENV);
const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const employeeTypeList = [{ type: "Employee" }, { type: "Contractor" }];
const filters = [
  "company",
  "division",
  "busCategory",
  "costcenter",
  "level",
  "department",
  "location",
  "portalcode",
];
const useStyles = makeStyles((theme) => {
  return {
    field: {
      marginTop: 20,
      marginBottom: 20,
      display: "block",
    },
    btn: {
      margin: theme.spacing(1),
      marginTop: 10,
      marginBottom: 20,
    },
    padDiv: {
      padding: 30,
    },
  };
});

const INITIAL_FORM_STATE = {
  preferredName: "",
  description: "",
  managersList: [],
  emailAlias: "",
  ownersList: [],
  employeeType: [],
  company: [],
  division: [],
  businessCategory: [],
  costCenter: [],
  level: [],
  department: [],
  location: [],
  portalCode: [],
};

let REQUEST = {
  groupName: "",
  emailAlias: "",
  authorizedUsersOnly: "",
  groupType: "",
  requestedBy: "",
  secondaryOwners: [],
};

const FORM_VALIDATION = Yup.object().shape({
  preferredName: Yup.string()
    .required("Required")
    .matches(
      /^DL_[a-zA-Z0-9_ -]*$/,
      "DL name should be alphanumeric and should start with DL_"
    ),
  description: Yup.string().required("Required"),
});
let emailAliasBaseValue = "aa.com";
if (process.env.REACT_APP_ENV === "dev") {
  emailAliasBaseValue = "dev.aa.com";
}
const emailAlias = [{ name: emailAliasBaseValue }, { name: "PSAAirlines.com" }];

const NewDLForm = () => {
  const classes = useStyles();
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [filterConditions, setFilterConditions] = useState("no");
  const [restrictAbility, setRestrictAbility] = useState("no");
  const [showFilterConditions, setShowFilterConditions] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [filterBtnDisable, setFilterBtnDisable] = useState(false);
  const [owners, setOwners] = useState([]);
  const [managers, setManagers] = useState([]);
  const [company, setCompany] = useState([]);
  const [division, setDivision] = useState([]);
  const [businessCategory, setBusinessCategory] = useState([]);
  const [costcenter, setCostCenter] = useState([]);
  const [level, setLevel] = useState([]);
  const [department, setDepartment] = useState([]);
  const [location, setLocation] = useState([]);
  const [portalcode, setPortalCode] = useState([]);
  const [snackBarSeverity, setsnackBarSeverity] = useState("");
  const [createNewDLResponse, setCreateNewDLResponse] = useState("");
  const [open, setOpen] = useState(false);
  const [showRemoveFiltersBtn, setShowRemoveFiltersBtn] = useState(false);
  const [emailAliasValue, setEmailAliasValue] = useState(emailAliasBaseValue);
  const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        var headers = new Headers();
        await Providers.globalProvider.getAccessToken().then(function (res) {
          const token = res;
          if (token) {
            var bearer = "Bearer " + token;
            headers.append("Authorization", bearer);
            var options = {
              method: "GET",
              headers: headers,
            };

            filters.forEach((filterName) => {
              var graphEndpoint = `${GROUP_API_URL}/api/GetFilterValues?filterKey=${filterName}`;

              fetch(graphEndpoint, options)
                .then((resp) => resp.json())
                .then((data) => {
                  if (filterName === "company") setCompany(data);

                  if (filterName === "division") setDivision(data);

                  if (filterName === "busCategory") setBusinessCategory(data);

                  if (filterName === "costcenter") setCostCenter(data);

                  if (filterName === "level") setLevel(data);

                  if (filterName === "department") setDepartment(data);

                  if (filterName === "location") setLocation(data);

                  if (filterName === "portalcode") setPortalCode(data);
                });
            });
          }
        });
      } catch (e) {
        throw new Error(e.message || "Something went wrong with api call");
      }
    })();
  }, []);

  const callCreateNewGroupAPI = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await CreateNewGroupAPI(token, request);
    });
  };

  const CreateNewGroupAPI = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var createNewGroupURL = GROUP_API_URL + "/api/CreateNewGroup";

    const res = await fetch(createNewGroupURL, options);
    data = await res.text();
    if (res.status !== 200) {
      setsnackBarSeverity("error");
    } else {
      setsnackBarSeverity("success");
    }
    setCreateNewDLResponse(data);
  };

  // const isNonOrgUserSelectedInPeople_old = (data) => {
  //   let isExternalUser = data.map(user => {
  //     if(!(user.personType && user.personType.subclass && user.personType.subclass === "OrganizationUser")){
  //       return true;
  //     }
  //     return false;
  //   }).filter(i => i === true);
  //   //console.log("** isExternalUser - " + isExternalUser)
  //   if(isExternalUser.length > 0) return true;
  //   else return false;
  // }
  const isNonOrgUserSelectedInPeople = (data) => {
    console.log(data);
    let isExternalUser = data.map(user => {
      if(!(user.userPrincipalName && user.userPrincipalName.includes("@corpaa.aa.com"))){
        return true;
      }
      return false;
    }).filter(i => i === true);
    //console.log("** isExternalUser - " + isExternalUser)
    if(isExternalUser.length > 0) return true;
    else return false;
  }

  const handleOwnerSelectionChanged = (e) => {
    //console.log(e.target.selectedPeople);
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      setOwners(e.target.selectedPeople);
    }
    else{
      setNonOrgUserSelected(true);
    }
  };

  const handleManagerSelectionChanged = (e) => {
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      setManagers(e.target.selectedPeople);
      if (e.target.selectedPeople.length > 0) {
        setEnableSubmit(false);
      }
      if (e.target.selectedPeople.length <= 0) {
        setEnableSubmit(true);
      }
    }
    else{
      setNonOrgUserSelected(true);
    }
  };

  const handleFilterConditions = (e) => {
    setFilterConditions(e.target.value);
    if (e.target.value === "no") {
      setShowFilterConditions(false);
      setFilterBtnDisable(false);
      setEnableSubmit(false);
    }
    if (e.target.value === "yes") {
      setShowFilterConditions(false);
      setFilterBtnDisable(true);
      setEnableSubmit(true);
    }
  };
  const handleRestrictAbility = (e) => {
    setRestrictAbility(e.target.value);
  };

  const handleAddFilters = () => {
    setShowRemoveFiltersBtn(true);
    setShowFilterConditions(true);
    setShowFilterSection(true);
  };

  const handleRemoveFilters = () => {
    setShowRemoveFiltersBtn(false);
    setShowFilterConditions(false);
    setShowFilterSection(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailAliasChange = (e) => {
    console.log(e.target.value);
    setEmailAliasValue(e.target.value);
  };
  return (
    <>
      <Container>
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION}
          onSubmit={async (values, onSubmitProps) => {
            values.ownersList = owners.map((owner) => owner.userPrincipalName);

            if (!showFilterConditions) {
              REQUEST = {};
              setManagers([]);
            }

            values.managersList = managers.map(
              (manager) => manager.userPrincipalName
            );
            values.emailAlias = emailAliasValue;
            console.log(values);

            REQUEST.emailAlias = emailAliasValue;

            REQUEST.groupName = values.preferredName.trim();
            REQUEST.groupType = "DistributionList";
            REQUEST.justification = values.description;
            REQUEST.secondaryOwners = values.ownersList;
            if (restrictAbility === "yes") {
              REQUEST.authorizedUsersOnly = true;
            }
            if (restrictAbility === "no") {
              REQUEST.authorizedUsersOnly = false;
            }
            if (!showFilterConditions) {
              REQUEST.managerFilter = values.managersList[0];
            }
            if (showFilterConditions) {
              REQUEST.attributeFilter = {
                employeeType: values.employeeType.map(
                  (employeeType) => employeeType.type
                ),
                division: values.division.map((division) => division.division),
                busCategory: values.businessCategory.map(
                  (businessCategory) => businessCategory.busCategory
                ),
                costCenter: values.costCenter.map(
                  (costCenter) => costCenter.costcenter
                ),
                level: values.level.map((level) => level.level),
                location: values.location.map((location) => location.location),
                portalCode: values.portalCode.map(
                  (portalCode) => portalCode.portalCode
                ),
                company: values.company.map((company) => company.companycode),
                department: values.department.map(
                  (department) => department.department
                ),
              };
            }
            await callCreateNewGroupAPI(REQUEST);
            setOpen(true);
            console.log(JSON.stringify(REQUEST));
            setEnableSubmit(true);
            console.log("*** FINISH ON SUBMIT ***");
          }}
        >
          {({
            values,
            touched,
            errors,
            dirty,
            isValid,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            setFieldValue,
            getFieldProps,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
              {/* <Breadcrumbs aria-label="breadcrumb" className={classes.field}>
                <Link
                  to="/home"
                  style={{ color: "gray", textDecoration: "none" }}
                >
                  Home
                </Link>
                <Link
                  to="/email-services"
                  style={{ color: "gray", textDecoration: "none" }}
                >
                  Email Services
                </Link>
                <Typography color="textPrimary">New Email DL</Typography>
              </Breadcrumbs> */}
              <Typography variant="h5" className={classes.field}></Typography>
              <Alert severity="info">
                Once Distribution List is created you can manage members in the list. If you need to create a DL over 50K members then contact Email.Services@aa.com
              </Alert>
              <Textfield
                required
                name="preferredName"
                label="Preferred Name"
                className={classes.field}
                inputProps={{maxLength:60}}
              />

              <TextField
                className={classes.field}
                select
                label="Email-Alias"
                value={emailAliasValue}
                onChange={handleEmailAliasChange}
                variant="outlined"
                fullWidth
              >
                {emailAlias.map((option, index) => (
                  <MenuItem key={index} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>

              <Textfield
                required
                name="description"
                className={classes.field}
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                inputProps={{maxLength:256}}
              />

              <Typography variant="h6" component="h2" className={classes.field}>
                Add secondary owner's
              </Typography>
              <PeoplePicker
                placeholder="Start typing user name... You can select multiple users"
                selectionChanged={handleOwnerSelectionChanged}
                className="people-picker"
                userType="user"
              />
              {isNonOrgUserSelected && <Alert severity="error">
                Selected User could not be resolved.
              </Alert>}

              <Typography variant="h6" component="h2" className={classes.field}>
                Would you like to restrict abilities to send emails to this
                distribution list to the requestor and secondary owners?
              </Typography>
              <RadioGroup
                value={restrictAbility}
                onChange={handleRestrictAbility}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  control={<Radio color="primary" />}
                  label="No"
                />
              </RadioGroup>             

              {showFilterSection === true && (
                <div className={classes.padDiv}>
                  <Alert severity="info">
                    Adding filter condition to a distribution list will restrict owners's ability to add / remove members. List members will be automatically updated based on the selected filter condition. 
                  </Alert>
                  <Typography variant="h6" component="h2" className={classes.field}>
                    Would you like to create a Manager Direct Reports distribution
                    list? (No other filter conditions can be applied)
                  </Typography>
                  <RadioGroup
                    value={filterConditions}
                    onChange={handleFilterConditions}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                  </RadioGroup>
                  {filterBtnDisable === true && (
                    <div>
                      <Typography
                        variant="h6"
                        component="h2"
                        className={classes.field}
                      >
                        Add Manager
                      </Typography>

                      <PeoplePicker
                        selectionMode="single"
                        placeholder="Start typing manager name"
                        selectionChanged={handleManagerSelectionChanged}
                        className="people-picker"
                        userType="user"
                      />
                      {isNonOrgUserSelected && <Alert severity="error">
                        Selected User could not be resolved.
                      </Alert>}
                    </div>
                  )}

                  {showFilterConditions === true && (
                    <div>
                      <Autocomplete
                        multiple
                        id="employeeType"
                        name="employeeType"
                        options={employeeTypeList}
                        getOptionLabel={(option) => option.type}
                        onChange={(e, value) => {
                          setFieldValue(
                            "employeeType",
                            value !== null ? value : ""
                          );
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="employeeType"
                            label="Employee Type"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="company"
                        name="company"
                        options={company}
                        getOptionLabel={(option) => option.companycode}
                        onChange={(e, value) => {
                          setFieldValue("company", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="company"
                            label="Company"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="division"
                        name="division"
                        options={division}
                        getOptionLabel={(option) => option.division}
                        onChange={(e, value) => {
                          setFieldValue("division", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="division"
                            label="Division"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="businessCategory"
                        name="businessCategory"
                        options={businessCategory}
                        getOptionLabel={(option) => option.busCategory}
                        onChange={(e, value) => {
                          setFieldValue(
                            "businessCategory",
                            value !== null ? value : ""
                          );
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="businessCategory"
                            label="Business Category"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="costCenter"
                        name="costCenter"
                        options={costcenter}
                        getOptionLabel={(option) => option.costcenter}
                        onChange={(e, value) => {
                          setFieldValue("costCenter", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="costCenter"
                            label="Cost Center"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="level"
                        name="level"
                        options={level}
                        getOptionLabel={(option) => option.level}
                        onChange={(e, value) => {
                          setFieldValue("level", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="level"
                            label="Level"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="department"
                        name="department"
                        options={department}
                        getOptionLabel={(option) => option.department}
                        onChange={(e, value) => {
                          setFieldValue("department", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="department"
                            label="Department"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="location"
                        name="location"
                        options={location}
                        getOptionLabel={(option) => option.location}
                        onChange={(e, value) => {
                          setFieldValue("location", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="location"
                            label="Location"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />

                      <Autocomplete
                        multiple
                        id="portalCode"
                        name="portalCode"
                        options={portalcode}
                        getOptionLabel={(option) => option.portalCode}
                        onChange={(e, value) => {
                          setFieldValue("portalCode", value !== null ? value : "");
                        }}
                        renderInput={(params) => (
                          <Textfield
                            name="portalCode"
                            label="Portal Code"
                            className={classes.field}
                            {...params}
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              )}
              {!showRemoveFiltersBtn && (
                  <Button
                  className={classes.btn}
                  disabled={filterBtnDisable}
                  variant="contained"
                  color="primary"
                  endIcon={<Add />}
                  onClick={handleAddFilters}
                >
                  Add Filters
                </Button>
              )}
              {showRemoveFiltersBtn && (
                <Button
                  className={classes.btn}
                  disabled={filterBtnDisable}
                  variant="contained"
                  color="primary"
                  endIcon={<Remove />}
                  onClick={handleRemoveFilters}
                >
                  Remove Filters
                </Button>
              )}

              <Button
                className={classes.btn}
                type="submit"
                variant="contained"
                color="primary"
                endIcon={<KeyboardArrowRight />}
                disabled={!dirty || !isValid || enableSubmit || isSubmitting || isNonOrgUserSelected}
              >
                Submit
              </Button>
              {isSubmitting && <CircularProgress />}

              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={open}
                autoHideDuration={null}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={snackBarSeverity}>
                  {createNewDLResponse}
                </Alert>
              </Snackbar>
            </form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default NewDLForm;
