import React, { useState, useEffect } from "react";
import { Autocomplete } from "@material-ui/lab";
import { Providers } from "@microsoft/mgt-element";
import {
  TextField,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Collapse,
  IconButton,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import { KeyboardArrowRight } from "@material-ui/icons";
import { PeoplePicker } from "@microsoft/mgt-react";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const filters = [
  "company",
  "division",
  "busCategory",
  "costcenter",
  "level",
  "department",
  "location",
  "portalcode",
];

const INITIAL_VALUES = {
  managersList: [],
  employeeType: [],
  company: [],
  division: [],
  businessCategory: [],
  costCenter: [],
  level: [],
  department: [],
  location: [],
  portalCode: [],
  managerSelect: false,
};

let person = [];
let managers = [];

const employeeTypeList = [{ type: "Employee" }, { type: "Contractor" }];

const useStyles = makeStyles((theme) => {
  return {
    field: {
      marginTop: 20,
      marginBottom: 20,
      display: "block",
    },
    btn: {
      margin: theme.spacing(1),
      marginTop: 10,
      marginBottom: 20,
    },
  };
});

const EmailFilters = (props) => {
  const classes = useStyles();

  const [company, setCompany] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [businessCategory, setBusinessCategory] = useState([]);
  const [costcenter, setCostCenter] = useState([]);
  const [level, setLevel] = useState([]);
  const [department, setDepartment] = useState([]);
  const [location, setLocation] = useState([]);
  const [portalcode, setPortalCode] = useState([]);
  const [checked, setChecked] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const [severity, setSeverity] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultEmployeeType, setDefaultEmployeeType] = useState([]);
  const [defaultDivisions, setDefaultDivisions] = useState([]);
  const [defaultBusCategory, setDefaultBusCategory] = useState([]);
  const [defaultCostCenter, setDefaultCostCenter] = useState([]);
  const [defaultLevel, setDefaultLevel] = useState([]);
  const [defaultLocation, setDefaultLocation] = useState([]);
  const [defaultPortalCode, setDefaultPortalCode] = useState([]);
  const [defaultCompany, setDefaultCompany] = useState([]);
  const [defaultDepartment, setDefaultDepartment] = useState([]);
  const [isNonOrgUserSelected, setNonOrgUserSelected] = useState(false); 
  const [disableBtn, setDisableBtn] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        var headers = new Headers();
        await Providers.globalProvider
          .getAccessToken()
          .then(async function (res) {
            const token = res;
            if (token) {
              var bearer = "Bearer " + token;
              headers.append("Authorization", bearer);
              var options = {
                method: "GET",
                headers: headers,
              };
              for (const filterName of filters) {
                var graphEndpoint = `${GROUP_API_URL}/api/GetFilterValues?filterKey=${filterName}`;

                await fetch(graphEndpoint, options)
                  .then((resp) => resp.json())
                  .then((data) => {
                    if (filterName === "company") setCompany(data);

                    if (filterName === "division") setDivisions(data);

                    if (filterName === "busCategory") setBusinessCategory(data);

                    if (filterName === "costcenter") setCostCenter(data);

                    if (filterName === "level") setLevel(data);

                    if (filterName === "department") setDepartment(data);

                    if (filterName === "location") setLocation(data);

                    if (filterName === "portalcode") setPortalCode(data);

                    // console.log(
                    //   `${filterName} data *** `,
                    //   JSON.stringify(data)
                    // );
                  });
              }
              let data = "";
              var dlNameWithFix = props.dlName.replace("&", "%26");
              var apiGetGroupDetails = `${GROUP_API_URL}/api/GetGroupByName?name=${dlNameWithFix}`;
              const res = await fetch(apiGetGroupDetails, options);
              data = await res.json();
              // console.log("apiGetGroupDetails API", JSON.stringify(data));
              // console.log(
              //   "ManagerFilet Value:",
              //   data.filter.managerFilterEnabled
              // );
              // console.log(
              //   "AttributeFilter Value:",
              //   data.filter.attributeFilterEnabled
              // );
              if (data.filter.managerFilterEnabled === true) {
                await callGetUserByID(data.filter.manager);
                console.log("UserDetails", person);
                setChecked(true);
              }
              if (data.filter.attributeFilterEnabled === true) {
                  let initEmployeeType =
                    data.filter.attributeFilter.employeeType.map(
                      (employeeType) => {
                        return { type: employeeType };
                      }
                    );
                  INITIAL_VALUES.employeeType = initEmployeeType;
                  setDefaultEmployeeType(initEmployeeType);
                  if (data.filter.attributeFilter.division !== null) {
                  let divisionValue = data.filter.attributeFilter.division.map(
                    (division) => {
                      return { division: division };
                    }
                  );
                  INITIAL_VALUES.division = divisionValue;
                  setDefaultDivisions(divisionValue);
                }

                if (data.filter.attributeFilter.busCategory !== null) {
                  let initBusCategory =
                  data.filter.attributeFilter.busCategory.map(
                    (busCategory) => {
                      return { busCategory: busCategory };
                    }
                  );
                  INITIAL_VALUES.businessCategory = initBusCategory;
                  setDefaultBusCategory(initBusCategory);
                }

                if (data.filter.attributeFilter.costCenter !== null) {
                  let initCostCenter =
                    data.filter.attributeFilter.costCenter.map((costCenter) => {
                      return { costcenter: costCenter };
                    });
                  INITIAL_VALUES.costCenter = initCostCenter;
                  setDefaultCostCenter(initCostCenter);
                }

                if (data.filter.attributeFilter.level !== null) {
                  let initLevel = data.filter.attributeFilter.level.map(
                    (level) => {
                      return { level: level };
                    }
                  );
                  INITIAL_VALUES.level = initLevel;
                  setDefaultLevel(initLevel);
                }

                if (data.filter.attributeFilter.location !== null) {
                  let initLocation = data.filter.attributeFilter.location.map(
                    (location) => {
                      return { location: location };
                    }
                  );
                  INITIAL_VALUES.location = initLocation;
                  setDefaultLocation(initLocation);
                }

                if (data.filter.attributeFilter.portalCode !== null) {
                  let initPortalCode =
                    data.filter.attributeFilter.portalCode.map((portalCode) => {
                      return { portalCode: portalCode };
                    });
                  INITIAL_VALUES.portalCode = initPortalCode;
                  setDefaultPortalCode(initPortalCode);
                }

                if (data.filter.attributeFilter.company !== null) {
                  let initCompany = data.filter.attributeFilter.company.map(
                    (company) => {
                      return { companycode: company };
                    }
                  );
                  INITIAL_VALUES.company = initCompany;
                  setDefaultCompany(initCompany);
                }

                if (data.filter.attributeFilter.department !== null) {
                  let initDepartment =
                    data.filter.attributeFilter.department.map((department) => {
                      return { department: department };
                    });
                  INITIAL_VALUES.department = initDepartment;
                  setDefaultDepartment(initDepartment);
                }
              }
            }
          });
      } catch (e) {
        console.log("Error", e);
      }
      console.log("END USEEFFECT");
      setLoading(false);
      setOpen(true);
    })();
    // eslint-disable-next-line
  }, []);

  const callGetUserByID = async (userID) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await getUserByID(token, userID);
    });
  };

  const getUserByID = async (token, userID) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "GET",
      headers: headers,
    };
    var apiGetUserByID = GROUP_API_URL + "/api/GetUserById?id=" + userID;

    const res = await fetch(apiGetUserByID, options);

    if (res.status !== 200) {
      data = await res.text();
      console.log("Error in apiGetUserByID", res.status, data);
    } else {
      data = await res.json();
      person[0] = data;
      console.log("apiGetUserByID", JSON.stringify(data));
    }
  };

  const callUpdateGroupFilters = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await updateGroupFilters(token, request);
    });
  };

  const updateGroupFilters = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiUpdateGroupFilters =
      GROUP_API_URL + "/api/UpdateGroupFilterConditions?name=" + props.dlName;

    const res = await fetch(apiUpdateGroupFilters, options);

    if (res.status !== 200) {
      data = await res.text();
      setSeverity("error");
    } else {
      data = await res.text();
      setSeverity("success");
      console.log("apiUpdateGroupFilters API", JSON.stringify(data));
    }
    console.log("data:", data);
    setOpen(true);
    setUpdateResult(data);
    setDisableBtn(true);
  };

  // const isNonOrgUserSelectedInPeople_old = (data) => {
  //   let isExternalUser = data.map(user => {
  //     if(!(user.personType && user.personType.subclass && user.personType.subclass === "OrganizationUser")){
  //       return true;
  //     }
  //     return false;
  //   }).filter(i => i === true);
  //   //console.log("** isExternalUser - " + isExternalUser)
  //   if(isExternalUser.length > 0) return true;
  //   else return false;
  // }
  const isNonOrgUserSelectedInPeople = (data) => {
    let isExternalUser = data.map(user => {
      if(!(user.userPrincipalName && user.userPrincipalName.includes("@corpaa.aa.com"))){
        return true;
      }
      return false;
    }).filter(i => i === true);
    //console.log("** isExternalUser - " + isExternalUser)
    if(isExternalUser.length > 0) return true;
    else return false;
  }
  
  const handleManagerSelectionChanged = (e) => {
    if(!isNonOrgUserSelectedInPeople(e.target.selectedPeople)){
      setNonOrgUserSelected(false);
      console.log("selected manager", e.target.selectedPeople);
      managers = e.target.selectedPeople;      
    }
    else{
      setNonOrgUserSelected(true);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpen(false);
  //   setDisableBtn(false);
  // };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...INITIAL_VALUES }}
        onSubmit={async (values) => {
          setLoading(true);
          const REQUEST = {};
          values.managerSelect = checked;
          REQUEST.groupName = props.dlName;
          values.managersList = managers.map(
            (manager) => manager.userPrincipalName
          );

          if (checked) {
            REQUEST.managerFilter = values.managersList[0];
          }

          if (!checked) {
            REQUEST.attributeFilter = {
              employeeType: values.employeeType.map(
                (employeeType) => employeeType.type
              ),
              division: values.division.map((division) => division.division),
              busCategory: values.businessCategory.map(
                (businessCategory) => businessCategory.busCategory
              ),
              costCenter: values.costCenter.map(
                (costCenter) => costCenter.costcenter
              ),
              level: values.level.map((level) => level.level),
              location: values.location.map((location) => location.location),
              portalCode: values.portalCode.map(
                (portalCode) => portalCode.portalCode
              ),
              company: values.company.map((company) => company.companycode),
              department: values.department.map(
                (department) => department.department
              ),
            };
            values = "";
          }
          console.log(JSON.stringify(REQUEST));

          callUpdateGroupFilters(REQUEST);
          setLoading(false);
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            {loading && (
              <Grid container direction="row" alignItems="center">
                <Typography variant="subtitle2">
                  Loading Data Please Wait ...
                </Typography>
                <CircularProgress />
              </Grid>
            )}
            {!loading && (
              <>
                {/* <Snackbar
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  open={open}
                  // autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity={severity}>
                    {updateResult}
                  </Alert>
                </Snackbar> */}
                <Alert severity="info">
                Adding filter condition to a distribution list will restrict owners's ability to add / remove members. List members will be automatically updated based on the selected filter condition. 
                </Alert>
                <Typography variant="h6" component="h2">
                  Would you like to create a Manager Direct Reports distribution
                  list? (No other filter conditions can be applied)
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      name="managerSelect"
                      color="primary"
                    />
                  }
                  label="yes"
                />

                {checked && (
                  <PeoplePicker
                    selectionMode="single"
                    placeholder="Start typing manager name"
                    selectedPeople={person}
                    selectionChanged={handleManagerSelectionChanged}
                    userType="user"
                  />
                )}
                {isNonOrgUserSelected && <Alert severity="error">
                          Selected User could not be resolved.
                        </Alert>}
                {!checked && (
                  <>
                    <Typography
                      variant="h6"
                      component="h2"
                      className={classes.field}
                    >
                      Add Filters
                    </Typography>
                    <Autocomplete
                      multiple
                      id="employeeType"
                      name="employeeType"
                      options={employeeTypeList}
                      getOptionLabel={(option) => option.type}
                      defaultValue={defaultEmployeeType}
                      onChange={(e, value) => {
                        setFieldValue(
                          "employeeType",
                          value !== null ? value : ""
                        );
                        setDefaultEmployeeType(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="employeeType"
                          label="Employee Type"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="company"
                      name="company"
                      options={company}
                      getOptionLabel={(option) => option.companycode}
                      defaultValue={defaultCompany}
                      onChange={(e, value) => {
                        setFieldValue("company", value !== null ? value : "");
                        setDefaultCompany(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="company"
                          label="Company"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="division"
                      name="division"
                      options={divisions}
                      getOptionLabel={(option) => option.division}
                      defaultValue={defaultDivisions}
                      onChange={(e, value) => {
                        setFieldValue("division", value !== null ? value : "");
                        setDefaultDivisions(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="division"
                          label="Division"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="businessCategory"
                      name="businessCategory"
                      options={businessCategory}
                      getOptionLabel={(option) => option.busCategory}
                      defaultValue={defaultBusCategory}
                      onChange={(e, value) => {
                        setFieldValue(
                          "businessCategory",
                          value !== null ? value : ""
                        );
                        setDefaultBusCategory(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="businessCategory"
                          label="Business Category"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="costCenter"
                      name="costCenter"
                      options={costcenter}
                      getOptionLabel={(option) => option.costcenter}
                      defaultValue={defaultCostCenter}
                      onChange={(e, value) => {
                        setFieldValue(
                          "costCenter",
                          value !== null ? value : ""
                        );
                        setDefaultCostCenter(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="costCenter"
                          label="Cost Center"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="level"
                      name="level"
                      options={level}
                      getOptionLabel={(option) => option.level}
                      defaultValue={defaultLevel}
                      onChange={(e, value) => {
                        setFieldValue("level", value !== null ? value : "");
                        setDefaultLevel(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="level"
                          label="Level"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="department"
                      name="department"
                      options={department}
                      getOptionLabel={(option) => option.department}
                      defaultValue={defaultDepartment}
                      onChange={(e, value) => {
                        setFieldValue(
                          "department",
                          value !== null ? value : ""
                        );
                        setDefaultDepartment(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="department"
                          label="Department"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="location"
                      name="location"
                      options={location}
                      getOptionLabel={(option) => option.location}
                      defaultValue={defaultLocation}
                      onChange={(e, value) => {
                        setFieldValue("location", value !== null ? value : "");
                        setDefaultLocation(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="location"
                          label="Location"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      multiple
                      id="portalCode"
                      name="portalCode"
                      options={portalcode}
                      getOptionLabel={(option) => option.portalCode}
                      defaultValue={defaultPortalCode}
                      onChange={(e, value) => {
                        setFieldValue(
                          "portalCode",
                          value !== null ? value : ""
                        );
                        setDefaultPortalCode(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          variant="outlined"
                          name="portalCode"
                          label="Portal Code"
                          className={classes.field}
                          {...params}
                        />
                      )}
                    />
                  </>
                )}
                <Collapse in={open}>
                  <Alert
                    severity={severity}
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {updateResult}
                  </Alert>
                </Collapse>

                <Button
                  className={classes.btn}
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<KeyboardArrowRight />}
                  disabled={disableBtn}
                >
                  Submit
                </Button>
                
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmailFilters;
