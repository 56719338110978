import React from "react";
import {
  Card,
  CardContent,
  makeStyles,
  Container,
  Typography,
} from "@material-ui/core";
import ManageDLForm from "./ManageDLForm";

const useStyles = makeStyles((theme) => {
  return {
    field: {
      marginTop: 20,
      marginBottom: 20,
      display: "block",
    },
    card: {
      marginTop: 20,
      maxWidth: "100%",
    },
  };
});

function DeleteDL() {
  const classes = useStyles();

  return (
    <>
      <Container>
        {/* <Breadcrumbs aria-label="breadcrumb" className={classes.field}>
          <Link to="/home" style={{ color: "gray", textDecoration: "none" }}>
            Home
          </Link>
          <Link
            to="/email-services"
            style={{ color: "gray", textDecoration: "none" }}
          >
            Email Services
          </Link>
          <Typography color="textPrimary">Manage DL</Typography>
        </Breadcrumbs> */}
        <Card className={classes.card}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Manage Email Distribution Lists
            </Typography>
            <Typography variant="body2" gutterBottom>
              This process is used by distribution list owners to manage their distribution groups (add or remove users).
              <br />
              Please, note: once you make a change it will take up to 2 hours for the change to be replicated on all systems including member and owner list on this page.
              <br />
              <br />
            </Typography>
            <ManageDLForm />
          </CardContent>
        </Card>
      </Container>
    </>
  );
}

export default DeleteDL;
