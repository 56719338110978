import React, { useState, useEffect } from "react";
import Header from "./Components/Header";
import RoutingPage from "./Pages/RoutingPage";

import { Providers, ProviderState } from "@microsoft/mgt-element";
import WelcomeHeader from "./Components/WelcomeHeader";

import { msalConfig } from "./Config/authConfig";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

Providers.globalProvider = new Msal2Provider(msalConfig);

function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return [isSignedIn];
}

function App() {
  const [isSignedIn] = useIsSignedIn();

  return (
    <>
      <Header />
      {/* {!isSignedIn && <LandingPage />} */}
      {!isSignedIn && <WelcomeHeader />}
      {isSignedIn && <RoutingPage />}
    </>
  );
}

export default App;
