import { DataGrid } from "@material-ui/data-grid";
import {
  Button,
  makeStyles,
  IconButton,
  Collapse,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Providers } from "@microsoft/mgt-element";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { Delete } from "@material-ui/icons";

const GROUP_API_URL = process.env.REACT_APP_AD_GROUP_API_URL;

const useStyles = makeStyles((theme) => {
  return {
    btn: {
      marginTop: 10,
    },
    // root: {
    //   "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
    //     display: "none"
    //   }
    // },
  };
});

const DataTable = ({ rows, columns, ...otherProps }) => {
  const classes = useStyles();
  const [removeBtnDisable, setRemoveBtnDisable] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowData, setRowData] = useState(rows);
  const [deletedRows, setDeletedRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [open, setOpen] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const [severity, setSeverity] = useState("");
  const [loading, setLoading] = useState(false);

  const callUpdateMembers = async (request) => {
    await Providers.globalProvider.getAccessToken().then(async function (res) {
      const token = res;
      await updateMembers(token, request);
    });
  };

  const updateMembers = async (token, request) => {
    let data = "";
    var headers = new Headers();
    var bearer = "Bearer " + token;
    headers.append("Authorization", bearer);
    var options = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    };
    var apiUpdateMembers = GROUP_API_URL + "/api/UpdateGroupMembers";

    const res = await fetch(apiUpdateMembers, options);

    if (res.status !== 200) {
      data = await res.text();
      setSeverity("error");
    } else {
      data = await res.text();
      setSeverity("success");
      console.log("updateMembers API", JSON.stringify(data));
    }
    console.log("data:", data);
    setUpdateResult(data);
  };

  const handleClick = () => {
    setLoading(true);
    console.log("Button clicked", selectedRows);
    setRowData(
      rows.filter((r) => selectedRows.filter((sr) => sr.id === r.id).length < 1)
    );

    const request = {
      groupName: otherProps.dlName,
      updateMembers: [],
    };
    //console.log(selectedRows.map((user) => user.userPrincipalName));
    request.updateMembers = selectedRows.map((obj) => {
      var objType = obj["@odata.type"];
      let memberType;
      let memberId;
      if (objType === "#microsoft.graph.user"){
        memberType = "User";
        memberId = obj.userPrincipalName;
      }
      else if (objType === "#microsoft.graph.orgContact"){
        memberType = "Contact";
        memberId = obj.mail;
      }
      else if (objType === "#microsoft.graph.group"){
        memberType = "DistributionList";
        memberId = obj.displayName;
      }
      return {
        requestType: "Remove",
        memberType: memberType,
        memberId: memberId,
      };
    });
    console.log("Request - ");
    console.log(JSON.stringify(request));

    setSelectedRows([]);
    console.log("Button clicked end", selectedRows);
    setRemoveBtnDisable(true);
    callUpdateMembers(request);
    setLoading(false);
    setOpen(true);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const handleRowSelect = (e) => {
    console.log("selected rows:", e.data);
    setDeletedRows([...deletedRows, ...rows.filter((r) => r.id === e.data.id)]);
    console.log("Delete rows", deletedRows);
  };

  const handleSelectionModelChange = (e) => {
    const selectedIDs = new Set(e);
    const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedRowData);
    console.log("selected rowData:", selectedRowData);

    if (selectedRowData.length > 0) {
      setRemoveBtnDisable(false);
    } else {
      setRemoveBtnDisable(true);
    }
  };
  return (
    <>
      <div>
        {/* {"Message: " + updateResult + loading} */}
        {loading && (
          <Grid container direction="row" alignItems="center">
            <Typography variant="subtitle2">
              Updating Please wait ...
            </Typography>
            <CircularProgress />
          </Grid>
        )}
        
      </div>
      <div style={{ height: 400, width: "100%" }}>
        <DataGrid className={classes.root}
          {...otherProps}
          rows={rowData}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
          rowsPerPageOptions={[5, 10, 20, 50]}
          pagination
          // checkboxSelection
          onRowSelected={handleRowSelect}
          onSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      {otherProps.showRemoveButton && !removeBtnDisable && (
        <Button
          className={classes.btn}
          disabled={removeBtnDisable}
          variant="contained"
          color="secondary"
          endIcon={<Delete />}
          onClick={handleClick}
        >
          Remove {selectedRows.length} Selected Users
        </Button>
      )}
      {!loading && (
          <Collapse in={open}>
            <Alert
              severity={severity}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {updateResult}
            </Alert>
          </Collapse>
        )}
    </>
  );
};

export default DataTable;
