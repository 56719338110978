import { Container, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import mail_img from "../../images/undraw_subscriber.svg";
//import ad_service_img from "../../images/undraw_Cloud_docs.svg";
import MediaCard from "./MediaCard";

const useStyles = makeStyles({
  header: {
    marginTop: 20,
  },
});

function Home() {
  const classes = useStyles();

  return (
    <Container className={classes.header}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <MediaCard
            to={"/email-services"}
            image={mail_img}
            title={"Email Services"}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <MediaCard
            // to={"/ad-services"}
            image={ad_service_img}
            title={"AD Services"}
          />
        </Grid> */}
      </Grid>
    </Container>
  );
}

export default Home;
