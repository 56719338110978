import React from "react";
import UnsubscribeDL from "../Components/UnsubscribeDL";

function UnsubscribeDLForm() {
  return (
    <div>
      <UnsubscribeDL />
    </div>
  );
}

export default UnsubscribeDLForm;
